import React, { useEffect } from 'react';

import Loader from '../Loader';
import '../../stylesheets/MortImpact.css'

import MaterialTable from "material-table";

import mortImpactLegend from '../../media/MortImpactLegend.png'

//components
import Plot from 'react-plotly.js';
import {Grid, Paper, Typography} from '@mui/material';

//transitions
import { Fade } from 'react-awesome-reveal';

// Google Global Site Tag
import ReactGA from 'react-ga';
import { salesApiFetch } from '../../containers/SalesContainer';
import { withTheme } from '@mui/styles';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const secondaryColor = "#cb5e27" //orange

function MortImpact(props){
  const [provId, setProvId] = React.useState(props.provId);
  const [mortImpactChart, setMortImpactChart] = React.useState({
    loading: true,
    data: {},
    shapes: []
  })

  useEffect(() => {
    runAsyncChartLoad()

    return () => {}
  }, []);
  
  useEffect(() => {
    if(provId != props.provId){
      //id changed so rerun apis
      setMortImpactChart({
        loading: true,
        data: {},
        shapes: []
      })

      runAsyncChartLoad()
      setProvId(props.provId)
    }
  }, [props.provId]);

  // useEffect(() => {
  //   if(user.selectedProviderId != props.provId){
  //     //id changed so rerun apis
  //     setMortImpactChart({
  //       loading: true,
  //       data: {},
  //       shapes: []
  //     })

  //     runAsyncChartLoad()
  //   }
  // }, [props.provId]);

  //#region async chart data load functions
  async function runAsyncChartLoad(){
    loadMortImpactChart()
    .then(() => {
      // console.log("done")
    })
    .catch((err) => {
      console.log(err)
    })
  }

  async function loadMortImpactChart(){
    let json = await salesApiFetch("GetDataTrindaVRDC", 12);

    var shapes = []
    var data = json.data.tableData
    
    var xPos = 0
    data.forEach(item => {
      var lineWidth = .05
      var lineGap = .075
      var rectWidth = .015
      var rectHeight = .5
      
      var rates = Object.keys(item).filter(x => x != "cohort")
      rates.forEach(rateName => {
        var rate = item[rateName];
        if(isNaN(rate)){
          //rate is not a useable number so skip
          return
        }

        var color = "";
        
        var line = {
          type: 'line',
          yref: 'x',
          yref: 'y',
          y0: rate,
          y1: rate,
          opacity: 0.7,
          line: {
            width: 2.5
          }
        }

        var rect = {
          type: 'rect',
          yref: 'x',
          yref: 'y',
          y0: rate - rectHeight,
          y1: rate + rectHeight,
          opacity: 1,
          line: {
            width: 0
          }
        }

        switch(rateName){
          case "hospitalrate":
            color = "red"
            line.x0 = xPos - lineWidth
            line.x1 = xPos + lineWidth

            rect.x0 = xPos - rectWidth
            rect.x1 = xPos + rectWidth
            break;
          case "nationalrate":
            color = "blue"
            line.x0 = ((xPos - lineWidth) - lineGap) - lineWidth
            line.x1 = ((xPos - lineWidth) - lineGap) + lineWidth
            
            rect.x0 = (((xPos - lineWidth) - lineGap) - lineWidth) - rectWidth + .05
            rect.x1 = (((xPos - lineWidth) - lineGap) - lineWidth) + rectWidth + .05
            break;
          case "mdaradjustedrate":
            color = "green"
            line.x0 = ((xPos + lineWidth) + lineGap) - lineWidth
            line.x1 = ((xPos + lineWidth) + lineGap) + lineWidth
            
            rect.x0 = (((xPos + lineWidth) - lineGap) + lineWidth) - rectWidth + .1
            rect.x1 = (((xPos + lineWidth) - lineGap) + lineWidth) + rectWidth + .1
            break;
        }

        line.line.color = color
        rect.fillcolor = color

        shapes = shapes.concat([line, rect])
      })
      
      xPos += 1
    })

    setMortImpactChart({
      loading: false,
      data: json.data,
      shapes
    })
  }
  //#endregion

  return (
		<Grid container justifyContent="center" alignItems="center" className="slideContainer">
      <Grid item xs={10} container  spacing={2} alignItems="center" justifyContent="center" style={{zIndex: 1}} >
        <Paper elevation={24} style={{width: "100%", padding: 16, backgroundColor:"rgba(255,255,255, .8)"}}>
          <Grid container alignItems="center" justifyContent="center" style={{position: "relative"}}>
            <Grid container alignItems="center" justifyContent="center" className="titleContainer">
              <Typography align="center" variant="h3" style={{color: "white"}}>
                Mortality Impact - Selected Cohorts
              </Typography>
            </Grid>

            <Grid item xs={11} container alignItems="center" justifyContent="center" spacing={1} style={{position: "relative", marginTop: "25px"}}>
              <div className="MortImpactBarDivider" />

              <Grid item xs={12} sm={6} md={5} lg={4} container alignItems="center" justifyContent="center" >
                <Fade triggerOnce duration={750} direction="down"
                  style={{
                    height: "250px",
                    width:"100%",
                  }}
                >
                  <Plot
                    style={{ height:"100%", width: "100%", backgroundColor:"transparent"}}
                    data={[
                      {
                        values: [12, 22, 22, 22, 22],
                        text: [null, "22%", null, null, null],
                        marker:{
                          colors: ["transparent", secondaryColor,"transparent","transparent","transparent"],
                          line: {
                            color: "black",
                            width: 2
                          }
                        },
                        name: 'MortHistChart',
                        hole: .4,
                        type: 'pie',
                        direction: 'clockwise',
                        sort: true,
                        hoverinfo: 'none',
                        textinfo: 'text',
                        textfont: {family: "Titillium Web"},
                        automargin: false
                      }
                    ]}
                    config={{
                      displayModeBar: false,
                      responsive: true
                    }}
                    layout={{
                      autosize: true, 
                      template: "simple_white",
                      font: { size: 20, family: "Titillium Web" },
                      margin: {t:25,b:25,l:25,r:25},
                      paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)",
                      showlegend: false,
                    }}
                  />
                </Fade>
                
                <Loader isLoading={mortImpactChart.loading} style={{minHeight: '200px'}}>
                  <Fade triggerOnce duration={750} direction="down" style={{ height: "100%", width:"100%"}}>
                    <MaterialTable
                      columns={[
                        { title: '30d Risk Rate Cohort', field: 'cohort' },
                        { title: 'National Rate', field: 'nationalrate' },
                        { title: 'Hospital Rate', field: 'hospitalrate' },
                        { title: 'MDAR Adjusted Rate', field: 'mdaradjustedrate' },
                      ]}
                      data={mortImpactChart.data.tableData}
                      options={{
                        search: false, toolbar: false, showTitle: false, sorting: false,
                        grouping: false, exportAllData: false, exportButton: false, 
                        paging: false, showTitle: false, showTextRowsSelected: true,
                        padding: "dense",
                        //use pre determined sizes to figure out height of this table
                        //subtract an extra 100 to account for header
                        headerStyle: {
                          zIndex: 0,
                          backgroundColor: secondaryColor,
                          color: "white",
                          fontFamily: "Titillium Web",
                          textAlign: 'center',
                          border: "solid black 1px",
                          position: 'sticky', top: 0
                        },
                        cellStyle: {
                          fontFamily: "Titillium Web",
                          textAlign: 'center',
                          paddingTop: '1px',
                          paddingRight: '8px',
                          paddingBottom: 0,
                          paddingLeft: '8px',
                        }
                      }}
                    />
                  </Fade>
                </Loader>
              </Grid>

              <Grid item xs={12} sm={6} md={7} lg={8} container alignItems="center" justifyContent="center" >
                <Loader isLoading={mortImpactChart.loading} style={{height: '450px'}}>
                  <Grid container>
                    <Grid container justifyContent="flex-end">
                      <img src={mortImpactLegend} style={{height: "100px"}} />
                    </Grid>

                    <Fade triggerOnce duration={750} direction="up" style={{width: "100%"}}>
                      <Plot
                        style={{height:"100%", width: "100%", backgroundColor:"transparent"}}
                        data={
                          [
                            {
                              name: "National Rate",
                              x: mortImpactChart.data.vrdcCohort,
                              y: mortImpactChart.data.nationalRate,
                              type: 'scatter',
                              mode: 'markers',
                              marker: {symbol:"line-ew", size:20, line: {color: "transparent", width: 2}},
                              textfont: {family: "Titillium Web"},
                              hoverinfo: 'skip',
                              automargin: false,
                            },
                            {
                              name: "Hospital Rate",
                              x: mortImpactChart.data.hospCohort,
                              y: mortImpactChart.data.hospRate,
                              type: 'scatter',
                              mode: 'markers',
                              marker: {symbol:"line-ew", size:20, line: {color: "transparent", width: 2}},
                              textfont: {family: "Titillium Web"},
                              hoverinfo: 'skip',
                              automargin: false,
                            },
                            {
                              name: "MDAR Adjusted",
                              x: mortImpactChart.data.vrdcCohort,
                              y: mortImpactChart.data.mdarAdjustedRate,
                              type: 'scatter',
                              mode: 'markers',
                              marker: {symbol:"line-ew", size:20, line: {color: "transparent", width: 2}},
                              textfont: {family: "Titillium Web"},
                              hoverinfo: 'skip',
                              automargin: false,
                            },
                          ]
                        }
                        config={{
                          displayModeBar: false,
                          displaylogo: false,
                          responsive: true
                        }}
                        layout={{
                          // title: "MDAR Mortality Impact: ",
                          autosize: true, showlegend: false,
                          margin:{t:0, l:100, b:150, r:0},
                          template: "simple_white",
                          font: { size: 20, family: "Titillium Web" },
                          xaxis: { showline: true, linecolor:'black', linewidth: 2, font: {size: 60} },
                          yaxis: { title: "Risk Rate", showline: true, linecolor:'black', linewidth: 2,range: [0,30]},
                          paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)",
                          shapes: mortImpactChart.shapes
                        }}
                      />
                    </Fade>
                  </Grid>
                </Loader>
              </Grid>
            </Grid>
            
            <Grid item xs={11} container alignItems="flex-end" style={{minHeight: '100px'}}>
              <Typography align="center" variant="h6">
                By documenting and coding the potentials MDAR identifies,
                the resulting change in risk adjustment will be reflected within the 30-day cohort rates.
                Illustrated above are your hospital’s forecasted rate adjustments in selected cohorts,
                as compared to the current hospital rate and national benchmarks.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withTheme(MortImpact);

