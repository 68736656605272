import React, { useEffect } from 'react';
import { salesApiFetch } from '../../containers/SalesContainer';

import '../../stylesheets/StarHistory.css';
import Loader from '../Loader';

import { withTheme } from '@mui/styles';

import {OpenCareJourneyBannerLink} from '../../App'

//imgs
import blueGrpahLogo from '../../media/background-line-graph-notext.webp'
import careJourneyLogo from '../../media/carejourney-logo.png'

//icons
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

//components
import Plot from 'react-plotly.js';

import {Grid, Paper, Typography} from '@mui/material';

//transitions
import { Fade } from 'react-awesome-reveal';

// Google Global Site Tag
import ReactGA from 'react-ga';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function StarHistory(props){
  const [smallMarkerMode, setIsSmallMarkerMode] = React.useState(false)
  const [markerSize, setMarkerSize] = React.useState(65)
  const [provId, setProvId] = React.useState(props.provId);
  const [starChart, setStarChart] = React.useState({
    loading: true,
    data: {}
  })
  
  useEffect(() => {
    window.addEventListener("resize", resizeMarker)
    resizeMarker()

    async function fetchData() {
      let json = await salesApiFetch("GetDataTrindaVRDC", 7);

      setStarChart({
        loading: false,
        data: {
          x: json.data.x,
          y: json.data.y
        }
      })
    }
    fetchData();

    return () => {
      window.removeEventListener("resize", resizeMarker)
    }
  }, []);
  
  useEffect(() => {
    if(provId != props.provId){
      //id changed so rerun apis
      setStarChart({
        loading: true,
        data: {}
      })

      
      async function fetchData() {
        let json = await salesApiFetch("GetDataTrindaVRDC", 7);

        setStarChart({
          loading: false,
          data: {
            x: json.data.x,
            y: json.data.y
          }
        })
      }
      fetchData();
      setProvId(props.provId)
    }
  }, [props.provId]);
  

  function resizeMarker(){
    var charElem = document.getElementsByClassName("starChartContainer");
    if(charElem.length == 0)
      return

    var chartWidth = charElem[0].clientWidth;

    let baseWidth = 1500;
    let baseMarkerSize = 80;

    let markerScale = chartWidth/baseWidth
    let newMarkerSize = baseMarkerSize * markerScale
    setMarkerSize(newMarkerSize)
    
    setIsSmallMarkerMode(newMarkerSize > 30 ? false : true)
  }

  return (
    <Grid container className="slideContainer" justifyContent="center"
      style={{ background: `url("${blueGrpahLogo}") bottom / cover no-repeat` }}
    >
      
      {/* Care Journey Banner */}
      <Grid item xs={10} container spacing={2} alignItems="center" style={{height: 50, zIndex: 2}}>
        <Paper style={{height: "100%", width:"100%", padding:"8px", marginTop: "50px", backgroundColor:"rgba(255,255,255, .9)"}} elevation={24}>
          <Grid item xs={12} style={{height: "100%"}}  container alignItems="center">
            <Grid item xs={12} style={{height: "100%"}} container alignItems="center">
              <Typography style={{fontWeight:"bold", marginRight: "5px"}}>Data provided by</Typography>

                <img style={{height: "27px", cursor:"pointer"}} src={careJourneyLogo} alt="Open Care Journey Link" onClick={OpenCareJourneyBannerLink}/>

              <OpenInNewIcon style={{cursor:"pointer"}} onClick={OpenCareJourneyBannerLink}/>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={10} container spacing={2} alignItems="center" justifyContent="center" style={{zIndex: 1}}>
        <Paper elevation={24} style={{width: "100%", padding:"16px", marginTop: "100px", backgroundColor:"rgba(255,255,255, .9)"}}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} container alignItems="center" className="titleContainer">
              <Typography align="center" variant="h4" style={{color: "White"}}>
                The Overall Star Rating provides consumers with a simple overall rating generated by combining multiple dimensions of quality into a single summary score.
              </Typography>
            </Grid>

            <Grid item xs={11}>
              <Typography align="center" variant="h6">
                The following illustrates your hospital’s Overall Star Rating over the past 4 releases, the most recent being the April 2021 publication.
              </Typography>
            </Grid>

            <Grid item xs={11}>
              <Loader isLoading={starChart.loading} className="starChartContainer">
                <Fade triggerOnce duration={750} direction="up" style={{height:"100%", width:"100%"}}>
                  <Grid container alignItems="center" justifyContent="center" style={{height:"100%"}}>
                    <Plot
                      className="starChart"
                      data={[
                        {
                          x: starChart.data.x,
                          y: starChart.data.y,
                          type: 'scatter',
                          mode: !smallMarkerMode ? 'markers+text' : 'markers',
                          marker: {
                            symbol: "star", 
                            size: markerSize, sizemode: "area",
                            color: "#FFD700", 
                            line:{color:"#000000", width: 2}
                          },
                          texttemplate:'%{x|%b %Y}',
                          textposition: 'bottom'
                        },
                      ]}
                      config={{
                        displayModeBar: false,
                        responsive: true
                      }}
                      layout={{
                        autosize: true,
                        showlegend: false,
                        template: "simple_white",
                        font: { size: 20, family: "Titillium Web" },
                        xaxis: {
                          visible: !smallMarkerMode ? false : true, 
                          showline: true, linewidth:2, linecolor:'black', fixedrange: true,
                          titlefont: {
                            family: 'Titillium Web',
                            size: !smallMarkerMode ? "0px" : "12px",
                            color: !smallMarkerMode ? 'transparent' : 'black'
                          }
                        },
                        yaxis: { title: "Star Rating", range: [0,5.99] , showline: true, linewidth:2, linecolor:'black' },
                        paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)"
                      }}
                    />
                  </Grid>
                </Fade>
              </Loader>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      
      <div className="starBottomBanner" />
    </Grid>
  );
}

export default withTheme(StarHistory);

