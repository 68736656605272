import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import MaterialTable from 'material-table';
import {Grid, Typography, Button} from '@mui/material';

import { adminApiFetch } from '../../config/msalConfig';
import store from '../../redux/store';

import _ from 'lodash'
import watch from 'redux-watch';

function Providers(props){
    const history = useHistory();
    const [user, setUser] = React.useState(_.cloneDeep(store.getState().userReducer.user))
    const [isLoading, setIsLoading] = React.useState(true)
    const [providerData, setProviderData] = React.useState([])

	useEffect(() => {
        let userWatch = watch(store.getState, 'userReducer')
        const userUnsubscribe = store.subscribe(userWatch((newVal, oldVal, objectPath) => {
            if(!_.isEqual(newVal.user, oldVal.yser))
              setUser(newVal.user)
        }))
        
        async function fetchData() {
            var providerData = await adminApiFetch('GetProviders')
            setProviderData(providerData)
            setIsLoading(false)
        }
        fetchData();

		return () => {
            userUnsubscribe()
        }
	}, [])

    function goToSales(){
        history.push("/Sales");
    }

    return (
        <Grid container style={{height: "100%", width: "100%"}}>
            <Grid item xs={12} style={{padding: "24px"}}>
                <MaterialTable
                    isLoading={isLoading}
                    columns={[
                        { title: 'Provider Id', field: 'providerId' },
                        { 
                            title: 'Hospital Names', field: 'hospitalNames', 
                            render: rowData => {
                                return(
                                    <>
                                        {
                                            rowData.hospitalNames.map(hospName => (
                                                <Typography>
                                                    {hospName}
                                                </Typography>
                                            ))
                                        }
                                    </>
                                )
                            } 
                        },
                        { 
                            title: 'Sales Link', 
                            render: rowData => {
                                let isDisabled =  user.providerIdList.map(x => x.providerId).includes(rowData.providerId)
                                //var link = `https://star.trindahealth.com?email=${user.email}&provid=${rowData.providerId}`
                                return (
                                    <Button variant="contained"
                                        disabled={isDisabled}
                                        className={isDisabled ? '' : "loginOpenBtn"}
                                        onClick={() => {
                                            store.dispatch({
                                                type: "SetAdminProviderId",
                                                provId: rowData.providerId,
                                                hospName: rowData.hospitalNames[0]
                                            })
                                            setIsLoading(true)

                                            goToSales()
                                            // validateEmail()
                                        }}
                                    >
                                        <b>Open Provider</b>
                                    </Button>
                                    // <a href={link} target="_blank">
                                    //     {link}
                                    // </a>
                                )
                            }
                        },
                    ]}
                    data={providerData}
                    options={{
                        cellStyle:{
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                        },
                        showTitle: false,
                        exportButton: true,
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default Providers;