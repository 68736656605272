import React from 'react';
import '../../stylesheets/MortImpactInfo.css'

//imgs
import assesmentPng from '../../media/trindaAssessment.png'

//components
import { Grid, Paper, Typography } from '@mui/material';

//transitions
import { Fade } from 'react-awesome-reveal';

// Google Global Site Tag
import ReactGA from 'react-ga';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function MortImpactInfo(props){
  return (
    <Grid container alignItems="center" justifyContent="center" className="slideContainer">
      <Grid item xs={10} container spacing={2} alignItems="center" justifyContent="center" style={{zIndex: 1}}>
        <Paper elevation={24} style={{minHeight: "100%", width:"100%", backgroundColor:"rgba(255,255,255, .8)", padding: "16px"}}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} container alignItems="center" justifyContent="center" className="titleContainer">
              <Typography align="center" variant="h3" style={{color: "White"}}>
                Where does MDAR have impact?
              </Typography>
            </Grid>

            <Fade triggerOnce duration={750} direction="left" className="MortImpactImg">
              <img src={assesmentPng} style={{height: "100%", width: "100%", objectFit:"contain"}}/>
            </Fade>
            
            <Grid item xs={10} style={{minHeight: "75px"}}>
              <Typography variant="h5" style={{textAlign: "center"}}>
                The MDAR tool focuses its strength on Quality Risk Adjustment by targeting those measures using risk adjustment in their calculations
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      
    </Grid>
  );
}

export default MortImpactInfo;

