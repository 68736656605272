import React, { useEffect } from 'react';
import '../../stylesheets/MortHist.css'
import MortHistFunnelLegend from '../../media/MortHistFunnelLegend.png'

import Loader from '../Loader'
import {primaryColor, secondaryColor} from '../../themes/theme'

import watch from 'redux-watch';
import store from '../../redux/store';

import Plot from 'react-plotly.js';

import {Grid, Paper, Typography} from '@mui/material';

import { Fade } from 'react-awesome-reveal';
import colorHash from '../../config/colorCohortHash';
import { withTheme } from '@mui/styles';
import { ScreenSizeNames } from '../../redux/reducers/deviceReducer';

const colorCohortHash = colorHash
function MortFunnel (props){
	const mortHistFunnel = props.mortHistFunnel
	const [isXSmallScreen, setIsXSmallScreen] = React.useState(false)
	const [isSmallScreen, setIsSmallScreen] = React.useState(false)

	useEffect(() => {
		let deviceWatch = watch(store.getState, 'deviceReducer')
		const deviceUnsubscribe = store.subscribe(deviceWatch((newVal, oldVal, objectPath) => {
			let sizeIsSmall = false
			let sizeIsXSmall = false

			if([ScreenSizeNames.xSmall, ScreenSizeNames.small].includes(newVal.screenSize))
				sizeIsSmall = true
				if(ScreenSizeNames.xSmall == newVal.screenSize)
					sizeIsXSmall = true

			setIsSmallScreen(sizeIsSmall)
			setIsXSmallScreen(sizeIsXSmall)
		}))	
	
		return () => {
			deviceUnsubscribe()
		}
	})

	return(
		<Grid container justifyContent="center" alignItems="center" className="slideContainer">
			<Grid item xs={10} spacing={1} container alignItems="center" justifyContent="center"style={{zIndex: 1}}>
				<Paper elevation={24} style={{width: "100%", backgroundColor:"rgba(255,255,255, .8)"}}>
					<Grid container alignItems="center" justifyContent="center" style={{padding: "16px", position: "relative"}}>
						<Grid item xs={12} container alignItems="center" justifyContent="center" className="titleContainer">
							<Typography align="center" variant="h3" style={{color: "white"}}>
								Mortality History
							</Typography>
						</Grid>

						<Grid item xs={11} container alignItems="flex-start" justifyContent="center" style={{minHeight: "60px"}}>
							<Typography gutterBottom variant="body1" style={{color: primaryColor }}>
								Mortality comprises 22% of your Star Rating. As illustrated below, it is a small group of encounters when compared to a hospital’s overall total of inpatient encounters.
							</Typography>
						</Grid>

						<Grid item xs={11} container alignItems="center" justifyContent="center" style={{position: "relative"}}>
							<Grid container>
								<Grid container style={{marginBottom: "50px"}}>
									<Grid item xs={12} sm={4} container alignItems="center" justifyContent="center" style={{minHeight: "85px"}}>
										<Typography gutterBottom variant={isSmallScreen ? "body1" : "h6"} style={{color: primaryColor }}>
											The funnel {isXSmallScreen ? 'below' :'on the right'}, for the most recent Star data collection period, the total Inpatient encounters, encounters that meet CMS Risk Model Criteria, encounters that meet the specific Mortality definitions, and encounters once specific rules and guidelines are applied.
										</Typography>
									</Grid>
									<Grid item xs={12} sm={8}>
										<Loader isLoading={mortHistFunnel.loading} style={{height: "400px"}}>
											<Fade triggerOnce duration={750} direction="down"
												style={{ height: "100%", width:"100%"}}
											>
												<Plot style={{ height:"100%", width: "100%", backgroundColor:"transparent"}}
													data={[
														{
															// orientation: "v",
															y: mortHistFunnel.data.funnel.y, //labels
															x: mortHistFunnel.data.funnel.x, //values
															name: 'MortHistFunnel',
															type: 'funnel',
															// type: 'bar',
															// texttemplate: `%{y} <br> %{x}`,
															texttemplate: `%{x}`,
															hoverinfo: 'none',
															// automargin: true,
															// textposition: "inside",
															connector: { fillcolor: 'rgba(14,61,70,0.5)' },
															marker: {
																color: [primaryColor,primaryColor,primaryColor, secondaryColor],
															}
														}
													]}
													config={{
														displayModeBar: false,
														responsive: true
													}}
													layout={{
														// autosize: true, 
														template: "simple_white",
														yaxis: {
															tickfont: {
																size: 16,
																family: "Titillium Web"
															}
														},
														font: { size: 20, family: "Titillium Web" },
														margin: {t:0,b:0,l:100,r:0},
														paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)",
														showlegend: false,
													}}
												/>
											</Fade>
										</Loader>
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={12} sm={6} md={4} container alignItems="center" justifyContent="center" style={{minHeight: "85px"}}>
										<Typography gutterBottom variant={isSmallScreen ? "body1" : "h6"} style={{color: primaryColor }}>
											The breakout {isXSmallScreen ? 'below' : 'on the right'} illustrates the distribution of the six cohorts that serve as inputs to Star Mortality.
											MDAR focuses its prioritization on these selected encounters.
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6} md={8}>
										<Loader isLoading={mortHistFunnel.loading} style={{height: "auto"}}>
											<Fade triggerOnce duration={750} direction="up" style={{width: "100%"}}>
												<Grid container justifyContent="flex-end" alignItems="center">

													<img src={MortHistFunnelLegend} style={{objectFit: 'container', width: '200px'}}/>

													<Grid item xs={12} md={6}>
														<Plot
															style={{height: "100px", width: "100%", backgroundColor:"transparent", border: "3px solid #cb5e27", borderRight: "0px transparent" }}
															data={
																Object.keys(mortHistFunnel.data.breakdown.cohorts)
																.map(cohort =>
																	{
																		var val = mortHistFunnel.data.breakdown.cohorts[cohort]
																		return {
																			y: ["Cohort Encounters"], //labels
																			x: [val], //values
																			name: cohort.replace("MORT-", ""),
																			type: 'funnel',
																			hoverinfo: 'none',
																			textinfo: 'none',
																			automargin: false,
																			marker_line_color:'#000000', marker_line_width:2,
																			marker: {
																				color: colorCohortHash[cohort.replace("MORT-", "MORT_")]
																			}
																		}
																	}
																)
															}
															config={{
																displayModeBar: false,
																responsive: true
															}}
															layout={{
																autosize: true,
																showlegend: false,
																yaxis: {
																	tickfont: {
																		size: 16,
																		family: "Titillium Web"
																	},
																	showline:true, linewidth:2, linecolor:'black'
																},
																xaxis: {
																	showline:true, linewidth:2, linecolor:'black'
																},
																margin:{t:0, l:0, b:0, r:0},
																template: "simple_white",
																font: { size: 16, family: "Titillium Web" },
																paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)"
															}}
														/>
													</Grid>
												</Grid>
											</Fade>
										</Loader>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	)
}


export default withTheme(MortFunnel);