import React, { useEffect } from 'react';
import { Route, MemoryRouter } from 'react-router';
import { BrowserRouter} from 'react-router-dom';

import _ from 'lodash';
import './App.css';

import Login from './components/Login'
import Layout from './components/Layout'
import Logs from './containers/admin/logs'
import Providers from './containers/admin/providers'
import Users from './containers/admin/users'

//components
import { withSnackbar } from 'notistack';
import Scrollbars from 'react-custom-scrollbars-2';

import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@mui/material/styles';
import SalesContainer from './containers/SalesContainer';
import CareJourneyContainer from './containers/CareJourneyContainer';

//Redux
import store from './redux/store';
import watch from 'redux-watch'

//Theme
import theme, { primaryColor, secondaryColor } from './themes/theme'

export function OpenCareJourneyBannerLink(){
  window.open('/CareJourney', '_blank');
}

function renderThumb({ style, ...props }){
  const thumbStyle = {
    backgroundColor: secondaryColor,
    borderRadius: 'inherit'
  };
  return (
    <div style={{ ...style, ...thumbStyle }} {...props}/>
  );
}

const windowHeight = window.innerHeight
function App(props){
  var currPath = window.location.pathname;
  var routesToSkipLogin = ['/carejourney']
  var skipLogin = routesToSkipLogin.includes(currPath.toLocaleLowerCase())

  const scrollBarRef = React.useRef()
  const [user, setUser] = React.useState(store.getState().userReducer.user)
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [loginIsOpen, setLoginIsOpen] = React.useState(skipLogin ? !skipLogin : store.getState().loginReducer.isOpen)
  
  useEffect(() => {
    let loginWatch = watch(store.getState, 'loginReducer')
    let snackBarWatch = watch(store.getState, 'snackReducer')
    let userWatch = watch(store.getState, 'userReducer')
    
    const loginUnsubscribe = store.subscribe(loginWatch((newVal, oldVal, objectPath) => {
      if(!_.isEqual(newVal.isOpen, oldVal.isOpen)){
        setLoginIsOpen(newVal.isOpen)
      }
    }))

    const snackUnsubscribe = store.subscribe(snackBarWatch((newVal, oldVal, objectPath) => {
      props.enqueueSnackbar(newVal.message, {...newVal.options})
    }))

    const userUnsubscribe = store.subscribe(userWatch((newVal, oldVal, objectPath) => {
      if(!_.isEqual(newVal.isAuthenticated, oldVal.isAuthenticated))
        setIsAuthenticated(newVal.isAuthenticated)

      if(!_.isEqual(newVal.user, oldVal.user))
        setUser(newVal.user)
    }))

    var debounced = _.debounce(windowResize, 250, { 'maxWait': 1000 });
    window.addEventListener("resize", debounced)

    return () => {
      snackUnsubscribe()
      loginUnsubscribe()
      userUnsubscribe()
      window.removeEventListener("resize", debounced)
    }
  })
  
  
  function windowResize(){
    var siteWidth = document.getElementById("MktSiteContainer").clientWidth

    store.dispatch({type:"SetScreenSize", size: siteWidth})
  }

  function scrollToTop(){
    scrollBarRef.current.scrollTop(0);
  }

  var routes=['/', '/carejourney'];
  if(isAuthenticated){
    routes = routes.concat(['/logs','/providers','/users'])
  }
  
  var startingIndex = routes.indexOf(currPath.toLowerCase());
  return (
    <ThemeProvider theme={theme}>
      <Grid container style={{height: windowHeight}}>
        <Login loginIsOpen={loginIsOpen}/>
      
        <BrowserRouter>
          <MemoryRouter initialEntries={routes} initialIndex={startingIndex}>
            <Layout isAuthenticated={isAuthenticated} scrollBarRef={scrollBarRef} renderThumb={renderThumb} scrollToTop={scrollToTop}>
              <Route exact path='/' component={() => <SalesContainer user={user} scrollToTop={scrollToTop}/>} />
              <Route path='/CareJourney' component={() => <CareJourneyContainer/>} />
              {
                isAuthenticated &&
                (
                  <>
                    <Route path='/Logs' component={Logs} />
                    <Route path='/Providers' component={Providers}/>
                    <Route path='/Users' component={Users}/>
                  </>
                )
              }
            </Layout>
          </MemoryRouter>
        </BrowserRouter>

      </Grid>
    </ThemeProvider>
  );
}

export default withSnackbar(App);

