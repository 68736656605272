import React from 'react';
import { Grid, CircularProgress } from '@mui/material';

function Loader (props) {
	const isLoading = props.isLoading;

	return (
		<Grid container className={props.className} style={props.style} alignItems="center" justifyContent="center">
			{
				isLoading ?
					<CircularProgress style={{height: "100px", width:"100px"}} />
				:
					<>
						{props.children}
					</>
			}
		</Grid>
	)
}

export default Loader
  