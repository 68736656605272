import React, { useEffect } from 'react';
import _ from 'lodash';

import { salesApiFetch } from '../../containers/SalesContainer';
import MortFunnel from '../MortHistSlides/Funnel';
import MortBar from '../MortHistSlides/Bar';

// Google Global Site Tag
import ReactGA from 'react-ga';
ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function MortHist(props){
  const [provId, setProvId] = React.useState(props.provId);
  const [mortOvrTimeChart, setMortOvrTimeChart] = React.useState({
    loading: true,
    data: []
  })

  const [mortTableData, setMortTableData] = React.useState({
    loading: true,
    data: []
  })

  const [mortHistFunnel, setMortHistFunnel] = React.useState({
    loading: true,
    data: {
      funnel: {
        x: [],
        y: []
      },
      breakdown: {
        total: 0,
        cohorts: []
      }
    }
  })

  useEffect(() => {
    runAsyncChartLoad()

    return () => {}
  }, []);
  
  useEffect(() => {
    if(provId != props.provId){
      //id changed so rerun apis
      setMortOvrTimeChart({
        loading: true,
        data: []
      })
      
      setMortTableData({
        loading: true,
        data: []
      })
      
      setMortHistFunnel({
        loading: true,
        data: {
          funnel: {
            x: [],
            y: []
          },
          breakdown: {
            total: 0,
            cohorts: []
          }
        }
      })

      runAsyncChartLoad()
      setProvId(props.provId)
    }
  }, [props.provId]);

  //#region async chart data load functions
  async function runAsyncChartLoad(){
    var promises = [
      loadMortalityCohortOverTimeGraphData(),
      loadMortalityCohortPieGraphData(),
      loadMortalityCohortTableData(),
    ];

    Promise.all(promises)
    .then(() => {
      // console.log("done")
    })
    .catch((err) => {
      console.log(err)
    })
  }

  async function loadMortalityCohortOverTimeGraphData(){
    let json = await salesApiFetch("GetDataTrindaVRDC", 8);

    setMortOvrTimeChart({
      loading: false,
      data: json.data
    })
  }
  
  async function loadMortalityCohortPieGraphData(){
    let json = await salesApiFetch("GetDataTrindaVRDC", 9);

    setMortHistFunnel({
      loading: false,
      data: json.data
    })
  }

  async function loadMortalityCohortTableData(){
    let json = await salesApiFetch("GetDataTrindaVRDC", 11);

    setMortTableData({
      loading: false,
      data: json.data
    })
  }
  //#endregion

  return (
    <>
      {/* Mort History - Funnel */}
      <MortFunnel mortHistFunnel={mortHistFunnel} />
      
      {/* Mort History - Table + Bar */}
      <MortBar mortOvrTimeChart={mortOvrTimeChart} mortTableData={mortTableData} />
    </>
  )
}

export default MortHist;

