import React from 'react';
import _ from 'lodash';
import '../../stylesheets/Highlights.css';

import { withTheme } from '@mui/styles';

//imgs

//icons

//components
import Plot from 'react-plotly.js';
import { withSnackbar } from 'notistack';

import { Grid, Paper, Typography, List, ListItem } from '@mui/material';

//transitions
import { Fade } from 'react-awesome-reveal';

// Google Global Site Tag
import ReactGA from 'react-ga';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const primaryColor = "#0e3d46" //teal
const secondaryColor = "#cb5e27" //orange
const mortGroupCharts = {
  items: [
    {
      name: "MORTALITY",
      values: [12, 22, 22, 22, 22],
      text: [null, "22%", null, null, null],
      markerColors : ["transparent", secondaryColor,"transparent","transparent","transparent"],
      color: secondaryColor,
      hdrColor: "white",
      listData: [
        "MORT-30-AMI", "MORT-30-HF", "MORT-30-PN",
        "MORT-30-COPD", "MORT-30-STK", "MORT-30-CABG", "PSI-4",
      ]
    },
    {
      name: "READMISSONS",
      color: primaryColor,
      hdrColor: "white",
      values: [12, 22, 22, 22, 22],
      text: [null, null, "22%", null, null],
      markerColors : ["transparent","transparent", primaryColor,"transparent","transparent"],
      listData: [
        "EDAC-30-AMI", "EDAC-30-HF", "EDAC-30-PN", "READM-30-COPD",
        "READM-30-CABG", "READM-30-HIP KNEE", "READM-30-HOSP WIDE",
        "OP-32", "OP-35-ADM", "OP-35 ED", "OP-36"
      ]
    },
    {
      name: "SAFETY",
      color: "#FEE7D9",
      hdrColor: "black",
      values: [12, 22, 22, 22, 22],
      text: [null, null, null,"22%",  null],
      markerColors : ["transparent","transparent","transparent","#FEE7D9","transparent"],
      listData: [
        "COMP-HIP-KNEE", "HAI-1", "HAI-2", "HAI-3", "HAI-4",
        "HAI-5", "HAI-6", "PSI-90"
      ]
    },
    {
      name: "PATIENT EXPERIENCE",
      color: "#A7A9AC",
      hdrColor: "white",
      values: [12, 22, 22, 22, 22],
      text: [null, null, null, null, "22%"],
      markerColors : ["transparent","transparent","transparent","transparent", "#A7A9AC"],
      mdarAdjust: true,
      listData: [
        "H-COMP-1","H-COMP-2","H-COMP-3","H-COMP-5",
        "H-COMP-6","H-COMP-7", "H-HSR-RATING", "H-CLEAN-HSP"
      ]
    },
    {
      name: "TIMELY & EFFECTIVE CARE",
      color: "#1A5669",
      hdrColor: "white",
      mdarAdjust: true,
      values: [12, 22, 22, 22, 22],
      text: ["12%", null, null, null, null],
      markerColors : ["#1A5669", "transparent","transparent","transparent", "transparent"],
      listData: [
        "IMM-3", "OP-22", "OP-23", "OP-29", "OP-30", "PC-01", "SEP-1",
        "ED-2B", "OP-3B", "OP-18B", "OP-8", "OP-10", "OP-13"
      ]
    }
  ]
};

function Highlights(props){
  return (
    <Grid container alignItems="center" justifyContent="center" className="slideContainer">
      <div className="highlightsTopBanner"/>

      <Grid item xs={10} container spacing={2} alignItems="center" justifyContent="center" style={{zIndex: 1}}>
        <Paper elevation={24} style={{width:"100%", backgroundColor:"rgba(255,255,255, .8)", padding: "16px"}}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} container alignItems="center" justifyContent="center">
              <Grid item xs={12} container alignItems="center" justifyContent="center" className="titleContainer">
                <Typography align="center" variant="h3" style={{color: "White"}}>
                  April 2021 Highlights
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography gutterBottom align="center" variant="h5">
                  Standardized measures are now organized into 5 groups
                </Typography>
              </Grid>
            </Grid>
            
            <Grid container spacing={1} alignItems="baseline" justifyContent="center">
              {
                mortGroupCharts.items.map((group, index) => {
                  return(
                    <Grid item container xs={12} sm={6} md={4} lg={2}>
                      <Grid item xs={6} sm={12}>
                        <Fade triggerOnce duration={750} delay={index * 100} direction="down" style={{width:"100%"}}>
                          <Plot className="pieChart"
                            data={[
                              {
                                values: group.values,
                                text: group.text,
                                marker:{
                                  colors: group.markerColors,
                                  line: { color: "black", width: 2 }
                                },
                                name: group.name + "Group",
                                hole: .4,
                                type: 'pie',
                                hoverinfo: 'none',
                                textinfo: 'text',
                                automargin: false,
                                direction: 'clockwise',
                                sort: true,
                              }
                            ]}
                            config={{
                              displayModeBar: false,
                              responsive: true
                            }}
                            layout={{
                              autosize: true, 
                              template: "simple_white",
                              font: { size: 20, family: "Titillium Web" },
                              margin: {t:10,b:10,l:10,r:10},
                              paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)",
                              showlegend: false,
                            }}
                          />
                        </Fade>
                      </Grid>
                    
                      <Grid item xs={6} sm={12}>
                        <Fade triggerOnce duration={750} delay={index * 100} direction="up" style={{width:"100%"}}>
                          <Grid item xs={12}>
                            <Grid item xs={12} container alignItems="center" justifyContent="center" style={{backgroundColor: group.color}}>
                              <Typography variant="h5" style={{color: group.hdrColor, textAlign: "center", fontFamily: "Titillium Web"}}>
                                {group.name}
                              </Typography>
                            </Grid>
  
                            <List
                              style={{ height: "200px", width:"100%" }}
                              sx={{
                                position: 'relative',
                                overflow: 'auto',
                              }}
                            >
                              {/* <Scrollbars style={{ width: "100%", maxHeight: "100%" }}> */}
                                {group.listData.map(x => {
                                  return (
                                    <ListItem style={{padding: "0px"}}>
                                      <Typography variant="h6" align="center" style={{fontSize: "16px"}}>
                                        {x}
                                      </Typography>
                                    </ListItem>
                                  )
                                })}
                              {/* </Scrollbars> */}
                            </List>
                          </Grid>
                        </Fade>
                      </Grid>
                    </Grid>
                  )
                })
              }
              
            </Grid>
            
            <Grid item xs={12} container alignItems="center" justifyContent="center" style={{minHeight: "200px", padding: "0px 8px"}}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Each group includes selected measures as shown in the corresponding tables
                </Typography>
              </Grid>

              {/* <Divider style={{width: '75%'}} /> */}

              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  The Mortality, Readmissions, and Safety groups all contain measures that employ risk adjustment methods in their calculations
                </Typography>
              </Grid>
              
              {/* <Divider style={{width: '75%'}} /> */}

              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Overall Measure Group scores are then calculated using a simple average
                </Typography>
              </Grid>
              
              {/* <Divider style={{width: '75%'}} /> */}

              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Weights are applied and hospitals are excluded or reweighted depending on the number of measures submitted
                </Typography>
              </Grid>
              
              {/* <Divider style={{width: '75%'}} /> */}

              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Hospitals are put into peer groups based on number of measure groups submitted
                </Typography>
              </Grid>
              
              {/* <Divider style={{width: '75%'}} /> */}

              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  Within each peer group, scores are split into 5 star rating groups using k-means clustering
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withTheme(Highlights);

