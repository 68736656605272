import _ from 'lodash'
const initState = {
    isAuthenticated: false,
    user: null,
    providerOvr: null,
    selectedProvId: null
}

export default function reducer(state = initState, action) {
    var user = _.cloneDeep(state.user)
    
    switch (action.type) {
        case "SetUser":
            return {
                isAuthenticated: action.isAuthenticated,
                user: action.user,
                selectedProvId: action.user.selectedProviderId
            }
        case "SwitchProviderId":
            user.selectedProviderId = action.provId
            return{
                ...state,
                user,
                selectedProvId: action.provId
            }
        case "SetAdminProviderId":
            user.selectedProviderId = action.provId
            
            if(!user.providerIdList.map(x => x.providerId).includes(action.provId)){
                user.providerIdList.push({
                    providerId: action.provId,
                    hospitalName: action.hospName
                })
            }

            return{
                ...state,
                user,
                selectedProvId: action.provId
            }
        case "ClearUser":
            return {
                isAuthenticated: false,
                user: null
            }
        default:
            return state
    }
}