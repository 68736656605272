import React, { useEffect } from 'react';
import _ from 'lodash';
import watch from 'redux-watch';

import store from '../redux/store';
import { msalFetch } from '../config/msalConfig';
import Loader from '../components/Loader';

//imgs
import trindaLogo from '../media/logo-trinda.png'
import trindaInverseLogo from '../media/logo-trinda-small-inverse.webp'

//icons
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

//components
import { Grid, AppBar, Toolbar, IconButton, FormControl, Select, MenuItem, useScrollTrigger } from '@mui/material';

// Google Global Site Tag
import ReactGA from 'react-ga';

//stylesheets
import '../stylesheets/SalesContainer.css';

//slides
import StarHistory from '../components/slides/StarHistory';
import Highlights from '../components/slides/Highlights';
import MortImpactInfo from '../components/slides/MortImpactInfo';
import MortHist from '../components/slides/MortHist';
import HccImpact from '../components/slides/HccImpact';
import MortROI from '../components/slides/MortROI';
import PostROI from '../components/slides/PostROI';
import Footer from '../components/slides/Footer';

//Theme
import { withTheme } from '@mui/styles';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

export async function salesApiFetch(path, datasetid = 0, extraBody = {}){
  var user = store.getState().userReducer.user;
  var selProvId = store.getState().userReducer.selectedProvId;

  try{
    //these props will always be needed so set them first
    var body = {
      email: user ? user.email : null,
      datasetid,
      ...extraBody
    }

    if(body.email == null){
      throw new Error("No Email")
    }
    
    //whenever we make a get data call we will always pass the selected provider id
    if(path === "GetDataTrindaVRDC"){
      body.providerId = selProvId
      path = `${path}-DS${datasetid}` //uncomment for python api use
    }

    return await msalFetch(path, body)
  }
  catch(err){
    console.log(err)
    return {status: false}
  }
}

function ScrollHandler(props){
  var mktSiteContainer = document.getElementById("MktSiteContainer");
  const [user, setUser] = React.useState(props.user);

  useEffect(() => {
    setUser(props.user)
  }, [props.user]);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: mktSiteContainer ? mktSiteContainer : props.window ? window() : undefined
  });

  var toolBarStyle={
    minHeight: '50px',
    transition: "0.3s ease",
    height: trigger ? "50px" : "70px"
  }

  var style = {
    backgroundColor: trigger ? "white" : "transparent",
    transition: "0.3s ease",
    boxShadow: "none",
  }
  
  if(trigger){
    delete style.boxShadow
  }

  return React.cloneElement(
    <AppBar position="fixed">
      <Toolbar style={toolBarStyle}>
        <Grid container style={{height: "100%", padding: "8px 0px"}}>
          <Grid item xs={2} md={1} style={{height: "100%", cursor: "pointer"}} onClick={() => window.open("https://trindahealth.com", '_blank')}>
            <div id="nav_logo"
              style={{
                height: "100%", width: "100%", transition: "0.3s ease",
                background: `url("${trigger ? trindaLogo : trindaInverseLogo}") center / contain no-repeat`
              }}
            />
          </Grid>

          <Grid item style={{flex: 1}}/>

          {user != null && (
            <Grid item xs={8} md={5} container justifyContent="flex-end" style={{height: "100%"}}>
              <FormControl variant="standard" style={{height: "100%"}}>
                <Select
                  disabled={user.providerIdList.length == 1}
                  disableUnderline
                  style={{
                    height: "100%",
                    transition: "0.3s ease",
                    color: trigger ? "black" : "white",
                    padding: "0px",                              
                  }}
                  MenuProps={{
                    //hideBackdrop: true,
                    disablePortal: true,
                    disableScrollLock: true,
                    anchorOrigin:{
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin:{
                      vertical: 'top',
                      horizontal: 'right',
                    },
                    getContentAnchorEl: null
                  }}
                  IconComponent={
                    (props) => (
                      <ArrowDropDownIcon  {...props} style={{transition: "0.3s ease", color: trigger ? "black" : "white"}} />
                    )
                  }
                  value={user.selectedProviderId}
                  onChange={(event) => props.switchSelectedProviderId(event)}
                  inputProps={{
                    transition: "0.3s ease",
                    color: trigger ? "black" : "white",
                    height: "100%",
                  }}
                >
                  {
                    user.providerIdList.map(item => (
                      <MenuItem
                        value={item.providerId} 
                        disabled={item.providerId === user.selectedProviderId} 
                        selected={item.providerId === user.selectedProviderId}
                      >
                        <b>{item.hospitalName} - {item.providerId}</b>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}

          <IconButton onClick={() => props.settingsBtnClick()} aria-label="settings" style={{padding: "0px", marginLeft: "8px"}}>
            <SettingsIcon style={{ transition: "0.3s ease", color: trigger ? "black" : "white"}} />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>, {style});
};

function SalesContainer(props){
  const user = props.user

  function switchSelectedProviderId(event){
    ReactGA.event({ // Google: Track request access click
      category: 'User',
      action: 'switch_providerid',
      label: 'Switch Provider ID - ' + event.target.value
    });

    var provId = event.target.value
    if(provId !== user.selectedProviderId){
      store.dispatch({type: "SwitchProviderId", provId})
      //setUser({...user, selectedProviderId: provId})
      
      //force scroll to top
      props.scrollToTop()
    }
  }

  function overrideLoginOpen(){
    store.dispatch({ type: "OpenLogin" })
  }
  
  const selProvId = user ? user.selectedProviderId : null
  return (
    <Loader isLoading={user === null} style={{height: "100%"}}>
      {/* nav bar */}
      <ScrollHandler {...props} user={user}
        settingsBtnClick={overrideLoginOpen}
        switchSelectedProviderId={switchSelectedProviderId}
      />

      <StarHistory provId={selProvId}/>
      
      <Highlights/>

      <MortImpactInfo />

      <MortHist provId={selProvId} />

      <HccImpact provId={selProvId} />     

      <MortROI provId={selProvId}/>

      <PostROI />

      <Footer />
    </Loader>
  );
}

export default withTheme(SalesContainer);

