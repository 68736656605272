import { createContext } from "react";

import main from '../themes/theme'

export const themes = {
  dark: "",
  light: main,
};

export const ThemeContext = createContext({
  theme: themes.light,
  changeTheme: () => {},
});
