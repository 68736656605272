import React, { useEffect } from 'react';
import _ from 'lodash';

import Loader from '../Loader';
import '../../stylesheets/HCCImpact.css'

//imgs

//icons

//components
import Plot from 'react-plotly.js';
import {Grid, Paper, Typography} from '@mui/material';

//transitions
import { Fade } from 'react-awesome-reveal';

// Google Global Site Tag
import ReactGA from 'react-ga';

import { ScreenSizeNames } from '../../redux/reducers/deviceReducer';
import watch from 'redux-watch';
import store from '../../redux/store';
import { salesApiFetch } from '../../containers/SalesContainer';
import { withTheme } from '@mui/styles';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const primaryColor = "#0e3d46" //teal
const secondaryColor = "#cb5e27" //orange

function HCCImpact(props){
	const [isSmallScreen, setISmallScreen] = React.useState(store.getState().deviceReducer.screenSize)
  const [provId, setProvId] = React.useState(props.provId);
  const [mortByHccImpact, setMortByHccImpact] = React.useState({
    loading: true,
    data: {
      countChart: [],
      impChart: []
    }
  })

  useEffect(() => {
    runAsyncChartLoad()

		let deviceWatch = watch(store.getState, 'deviceReducer')
		const deviceUnsubscribe = store.subscribe(deviceWatch((newVal, oldVal, objectPath) => {
			let sizeIsSmall = false

			if([ScreenSizeNames.xSmall, ScreenSizeNames.small].includes(newVal.screenSize))
				sizeIsSmall = true

			setISmallScreen(sizeIsSmall)
		}))

    return () => {
			deviceUnsubscribe()
    }
  }, []);

  useEffect(() => {
    if(provId != props.provId){
      //id changed so rerun apis
      setMortByHccImpact({
        loading: true,
        data: {
          countChart: [],
          impChart: []
        }
      })

      runAsyncChartLoad()
      setProvId(props.provId)
    }
  }, [props.provId]);

  function unpack(rows, key) {
    if(_.isEmpty(rows))
      return []
  
    return rows.map(function(row) { return row[key]; });
  }

  //#region async chart data load functions
  async function runAsyncChartLoad(){
    loadHccCntImpChartData()
    .then(() => {
      // console.log("done")
    })
    .catch((err) => {
      console.log(err)
    })
  }

  async function loadHccCntImpChartData(){
    let json = await salesApiFetch("GetDataTrindaVRDC", 10);

    setMortByHccImpact({
      loading: false,
      data: json.data
    })
  }
  //#endregion

  return (
		<Grid container justifyContent="center" alignItems="center" className="slideContainer">
      <Grid item xs={10} container spacing={2} alignItems="center" justifyContent="center" style={{zIndex: 1}}>
        <Paper elevation={24} style={{width: "100%", backgroundColor:"rgba(255,255,255, .8)"}}>
          <Grid container alignItems="center" justifyContent="center" style={{ padding: "16px"}}>
            <Grid item xs={12} container alignItems="center" justifyContent="center" className="titleContainer">
              <Typography align="center" variant="h3" style={{color: "white"}}>
                HCC and Impact - Selected Cohorts
              </Typography>
            </Grid>
            
            <Grid item xs={11} container alignItems="flex-start" justifyContent="center" style={{minHeight: "80px"}}>
              {
                !isSmallScreen ?
                  <Typography gutterBottom variant="body1" style={{ color: primaryColor }}>
                    MDAR identifies "currents and potentials", chronic conditions and comorbidities 
                    that correctly captured, can lead to more accurate ratings and reimbursements.
                    Currents identified by MDAR are the diagnoses included on the patient record and impactful 
                    in the cohort risk model. Concurrently, the algorithm also produces potentials, 
                    which if clinically relevant and added to the patient record, also impact the risk model.
                  </Typography>
                :
                  <>
                    <Typography gutterBottom variant="body1" style={{ color: primaryColor }}>
                      MDAR identifies "currents and potentials", chronic conditions and comorbidities 
                      that correctly captured, can lead to more accurate ratings and reimbursements.
                    </Typography>
                    <Typography gutterBottom variant="body1" style={{ color: primaryColor }}>
                      Currents identified by MDAR are the diagnoses included on the patient record and impactful 
                      in the cohort risk model. Concurrently, the algorithm also produces potentials, 
                      which if clinically relevant and added to the patient record, also impact the risk model.
                    </Typography>
                  </>
              }
            </Grid>
          
            <Grid container spacing={2} style={{marginBottom:"25px"}}>
              <Grid item xs={12} md={6}>
                <Loader isLoading={mortByHccImpact.loading} className="HCCImpactChart">
                  <Fade triggerOnce duration={750} direction="left" style={{ height: "100%", width:"100%"}}>
                    <Plot
                      onHover={(e) => {
                      }}
                      style={{ height:"100%", width: "100%", backgroundColor:"transparent"}}
                      data={[
                        {
                          name: 'Count',
                          type: 'sunburst',
                          automargin: false,
                          branchvalues: 'total',
                          textposition: 'inside',
                          insidetextorientation: 'radial',
                          ids: unpack(mortByHccImpact.data.countChart, 'ids'),
                          values: unpack(mortByHccImpact.data.countChart, 'values'),
                          labels: unpack(mortByHccImpact.data.countChart, 'labels'),
                          parents: unpack(mortByHccImpact.data.countChart, 'parents'),
                          textfont: {family: "Titillium Web"},
                        }
                      ]}
                      config={{
                        displayModeBar: false,
                        responsive: true
                      }}
                      layout={{
                        autosize: true,
                        title: 'Mortality Encounters:<br>Counts by HCC',
                        template: "simple_white",
                        font: { size: 16, family: "Titillium Web" },
                        margin: {t:80,b:0,l:10,r:10},
                        paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)",
                        showlegend: false,
                      }}
                    />
                  </Fade>
                </Loader>
              </Grid>

              <Grid item xs={12} md={6}>
                <Loader isLoading={mortByHccImpact.loading} className="HCCImpactChart">
                  <Fade triggerOnce duration={750} direction="left" style={{ height: "100%", width:"100%"}}>
                    <Plot
                      style={{ height:"100%", width: "100%", backgroundColor:"transparent"}}
                      data={[
                        {
                          branchvalues: 'total',
                          textposition: 'inside',
                          insidetextorientation: 'radial',
                          ids: unpack(mortByHccImpact.data.impChart, 'ids'),
                          values: unpack(mortByHccImpact.data.impChart, 'values'),
                          labels: unpack(mortByHccImpact.data.impChart, 'labels'),
                          parents: unpack(mortByHccImpact.data.impChart, 'parents'),
                          name: 'Impact',
                          type: 'sunburst',
                          textfont: {family: "Titillium Web"},
                          automargin: false
                        }
                      ]}
                      config={{
                        displayModeBar: false,
                        responsive: true
                      }}
                      layout={{
                        autosize: true,
                        title: 'Mortality Encounters:<br>Risk Impact By HCC',
                        template: "simple_white",
                        font: { size: 16, family: "Titillium Web" },
                        margin: {t:80,b:0,l:10,r:10},
                        paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)",
                        showlegend: false,
                      }}
                    />
                  </Fade>
                </Loader>
              </Grid>
            </Grid>
            
            <Grid item xs={10} style={{minHeight: "80px"}}>
              <Typography gutterBottom variant="body1" style={{ color: primaryColor }}>
                Currents and potentials found by MDAR for 2016Q3 – 2019Q2 are displayed for 30 day Mortality for Acute Myocardial Infarction (AMI) 
                and Pneumonia (PN). They are further broken down into HCC (Hierarchical Condition Categories) groupings in two separate sunbursts. 
                The left is subdivided by counts within each HCC grouping. The sunburst on the right subdivides based on the risk impact of the HCC 
                within that cohort risk model.
              </Typography>
            </Grid>
            
            <Grid item xs={10} style={{minHeight: "80px"}}>
              <Typography gutterBottom variant="body1" style={{ color: primaryColor }}>
                To illustrate further, locate the section for Pneumonia (PN) Potentials and the HCC Protein Calorie Malnutrition on each sunburst. 
                On the left, the section size represents the count of potentials found, those with a possible diagnosis classified to Protein Calorie Malnutrition. 
                On the right, the HCC subdivision represents the estimated risk adjustment impact of those potentials if captured.
              </Typography>
            </Grid>
            
            <Grid item xs={10} style={{minHeight: "40px"}}>
              <Typography gutterBottom variant="body1" style={{ color: primaryColor }}>
                A small number of encounter potentials, as seen on the left, can lead to a large risk adjustment impact, as seen on the right. 
                The MDAR tool will prioritize displaying these potentials with high risk adjustment impact.
              </Typography>
            </Grid>
          </Grid>                  
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withTheme(HCCImpact);

