import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';
import Collapse from '@material-ui/core/Collapse';

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";

import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./config/msalConfig";
import { Provider } from 'react-redux'
import store from './redux/store'

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextWrapper>
      <MsalProvider instance={msalInstance}>
        <SnackbarProvider maxSnack={3} preventDuplicate autoHideDuration={2500}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          TransitionComponent={Collapse}
        >
          <Provider store={store}>
            <App />
          </Provider>
        </SnackbarProvider>
      </MsalProvider>
    </ThemeContextWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
