import React, { useEffect } from 'react';
import _ from 'lodash';

//components
import { Grid, Paper, Typography, AppBar, Toolbar, IconButton, FormControl, Select, MenuItem, useScrollTrigger } from '@mui/material';

// Google Global Site Tag
import ReactGA from 'react-ga';

import careJourneyLogo from '../media/carejourney-logo.png';

//stylesheets
import '../stylesheets/CareJourneyContainer.css';

//Theme
import { withTheme } from '@mui/styles';

function CareJourneyContainer() {

  useEffect(() => {

  }, [])

  return (
    <Grid container alignItems="center" justifyContent="center" className="CJBackground"
      style={{
      }}
    >
      <Grid item xs={12} sm={10} md={8} container className="CJContainer">
        <Paper className="CJPaper" elevation={10} style={{backgroundColor:"rgba(255,255,255, .9)"}}>
          <Grid container alignItems="center" justifyContent="center" style={{height: "100%", position:"relative"}}>
            <Grid item xs={11} container style={{flex: 1}}>
              <Grid item xs={12} container justifyContent="center">
                <img style={{height: "50px"}} src={careJourneyLogo} alt="Care Journey"/>
              </Grid>
              <Grid item xs={12} className='CJSectionText'>
                <Typography variant="h5" gutterBottom>
                  CareJourney is leading healthcare data analytics company that provides value-based care organizations 
                  with open clinically-relevant analytics and insights to better manage total cost, 
                  quality outcomes and network performance. CareJourney has access to a robust 
                  data set: 100% of Medicare Fee-For-Service Part A, B and D claims at a beneficiary level 
                  as well as commercial claims data.
                </Typography>
              </Grid>
              <Grid item xs={12} className='CJSectionText'>
                <Typography variant="h5" gutterBottom>
                  Trinda Health leveraged CareJourney’s DaaS services and nationwide longitudinal claims data to 
                  develop a suite of tailored reports based on Trinda Health’s core data model to support their client engagements, 
                  focusing on benchmarked cost and outcome performance, risks on readmission for different cohorts 
                  (COPD, heart failure, AMI, THA/TKA) and clinical documentation improvement opportunities.
                </Typography>
              </Grid>
              <Grid item xs={12} className='CJSectionText'>
                <Typography variant="h5" gutterBottom>
                  Trinda Health has used that data to draw additional insights on quality and risk adjustment performance,
                   market share, and relationships for both their client base and their own value-based care entities, 
                   and identify market opportunities to further expand their business.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withTheme(CareJourneyContainer);