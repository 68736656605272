export const ScreenSizeNames = {
    xSmall: "XS",
    small: "SM",
    medium: "MD",
    large: "LG",
    xLarge: "XL"
}

const screenSizes = [
    {size: ScreenSizeNames.xSmall, min:0, max: 600},
    {size: ScreenSizeNames.small, min:601, max: 900},
    {size: ScreenSizeNames.medium, min:901, max: 1200},
    {size: ScreenSizeNames.large, min:1201, max: 1536},
    {size: ScreenSizeNames.xLarge, min:1537, max: 999999}
]

const initState = {
    screenSize: ScreenSizeNames.large
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case "SetScreenSize":
            var size = ScreenSizeNames.large

            screenSizes.forEach(screen => {
                if(screen.min <= action.size && action.size <= screen.max){
                    size = screen.size
                }
            })

            return {
                screenSize: size
            }
        default:
            return state
    }
}