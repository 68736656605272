import { combineReducers } from 'redux';

import userReducer from './userReducer';
import snackReducer from './snackReducer';
import loginReducer from './loginReducer'
import deviceReducer from './deviceReducer'

export default combineReducers({
    userReducer,
    snackReducer,
    loginReducer,
    deviceReducer
});