const initState = {
    isOpen: true
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case "OpenLogin":
            return {
                ...state,
                isOpen: true
            }
        case "CloseLogin":
            return {
                ...state,
                isOpen: false
            }
        default:
            return state
    }
}