export default function reducer(state = { message: "", options: { variant: "", autoHideDuration: getHideDuration(""), action: null }}, action) {
    switch (action.type) {
        case "ShowSnack":

            return {
                message: action.message,
                options:
                {
                    variant: action.variant,
                    action: action.action ?? null,
                    style: action.style
                }
            }
        case "HideSnack":
            return {
                snackbarObj: {msg: "", snacktype: ""}
            }
        default:
            return state
    }
}

function getHideDuration(duration) {
    if (!duration || isNaN(duration)) {
        switch (duration) {
            case "short":
            case "SHORT":
                duration = 4000;
                break;
            case "long":
            case "LONG":
                duration = 8000;
                break;
            default:
                duration = 6000;
                break;
        }
    }

    //default duration if its not a number
    return duration;
}