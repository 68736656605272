import React from 'react';
import _ from 'lodash';

import { salesApiFetch } from '../../containers/SalesContainer'
import store from '../../redux/store';

//imgs
import handIpadGraphLogo from '../../media/background-graph-ipad.jpg'
import trindaOrangeBars from '../../media/icon-trinda-orange.webp'

import { Grid, Typography, Button, CircularProgress } from '@mui/material';

//transitions
import { AttentionSeeker } from 'react-awesome-reveal';

import { withTheme } from '@mui/styles';

// Google Global Site Tag
import ReactGA from 'react-ga';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const primaryColor = "#0e3d46" //teal
const secondaryColor = "#cb5e27" //orange

function Footer(props){
  const [requestLoading, setIsRequestLoading] = React.useState(false)

  async function requestDemo(){
    ReactGA.event({ // Google: Track request access click
      category: 'User',
      action: 'demo_request',
      label: 'Footer - User Requested Demo'
    });

    try{
      let json = await salesApiFetch("SetLogRecord", 0, {action: "Demo Request"});
      if(json.status){
        store.dispatch({
          type: "ShowSnack",
          message: "Your Demo Request Has Been Submitted",
          variant: 'Success',
        })
      }
    }
    catch(err){
      store.dispatch({
        type: "ShowSnack",
        message: err.message,
        variant: 'Error',
      })
    }

    setIsRequestLoading(false)
  }

  return (
    <Grid container
      style={{
        height: "650px", position:"relative",
        background: `linear-gradient(black 0%, rgba(203,94,39,0.75) 0%, rgba(14,61,71,0.8) 78%), url("${handIpadGraphLogo}") center / cover no-repeat`
      }}
    >
      <Grid item xs={12} container alignItems="center" justifyContent="center">
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={4} container justifyContent="center" style={{marginBottom: "25px"}}>
            <img src={trindaOrangeBars} />
          </Grid>
          <Grid item xs={10} style={{marginBottom: "25px"}}>
            <Typography variant="h4" align="center"  style={{color:"white"}}>
              Want to see more?
            </Typography>
          </Grid>

          <Grid item xs={12} container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={8} md={4} container alignItems="center" justifyContent="center"> 
              <AttentionSeeker effect="pulse" style={{height: "100%", width: "100%"}}>
                <Grid container alignItems="center" justifyContent="center" style={{height: "100%"}}>
                  <Button variant="contained"
                    style={{
                      width: "250px", fontFamily: "Titillium Web",
                      backgroundColor: "white", color: secondaryColor
                    }}
                    onClick={() => {
                      setIsRequestLoading(true)
                      requestDemo()
                    }}
                  >
                    {
                      requestLoading ?
                        <CircularProgress size={30} thickness={5} style={{color: secondaryColor}}/>
                      :
                        <b>Request a Demo</b>
                    }
                  </Button>
                </Grid>
              </AttentionSeeker>
            </Grid>
          </Grid>
        
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withTheme(Footer);

