import React from 'react';
import '../../stylesheets/MortHist.css'
import MortHistFunnelLegend from '../../media/MortHistFunnelLegend.png'

import Loader from '../Loader'
import {primaryColor, secondaryColor} from '../../themes/theme'

import Plot from 'react-plotly.js';
import MaterialTable from "material-table";

import {Grid, Paper, Typography} from '@mui/material';

import { Fade } from 'react-awesome-reveal';
import colorHash from '../../config/colorCohortHash';
import { withTheme } from '@mui/styles';

const colorCohortHash = colorHash
function MortBar(props){
	const mortOvrTimeChart = props.mortOvrTimeChart
	const mortTableData = props.mortTableData	

	return(
		<Grid container justifyContent="center" alignItems="center"className="slideContainer">
			<Grid item xs={10} spacing={1} container alignItems="center" justifyContent="center" style={{zIndex: 1}}>
				<Paper elevation={24} style={{width: "100%", backgroundColor:"rgba(255,255,255, .8)"}}>
					<Grid container alignItems="center" justifyContent="center" style={{height: "100%", padding: "16px", position: "relative"}}>
						<Grid item xs={12} container alignItems="center" justifyContent="center" className="titleContainer">
							<Typography align="center" variant="h3" style={{color: "white"}}>
								Mortality History
							</Typography>
						</Grid>
						
						<Grid item xs={11} container alignItems="flex-start" justifyContent="center" style={{minHeight: "70px"}}>
							<Typography variant="body1" style={{color: primaryColor}}>
								The data table includes 30-day risk rates, which serve as inputs for the Mortality Group Score, and the national averages 
								for the most recent Star data collection period. The bar graph demonstrates the cohort encounter breakdowns by quarter through 
								the same collection period and four additional quarters following.
							</Typography>
						</Grid>

						<Grid item xs={12} container spacing={1} style={{position: "relative"}}>
							<div className="MortBarDivider" />

							<Grid item xs={12} sm={5} md={5} lg={4}>
								<Grid item xs={12}>
									<Fade triggerOnce duration={750} direction="down"
										style={{
											height: "250px", width:"100%",
										}}
									>
										<Plot
											style={{ height:"100%", width: "100%", backgroundColor:"transparent"}}
											data={[
												{
													values: [12, 22, 22, 22, 22],
													text: [null, "22%", null, null, null],
													marker:{
														colors: ["transparent", secondaryColor, "transparent", "transparent", "transparent"],
														line: {
															color: "black",
															width: 2
														}
													},
													name: 'MortHistChart',
													hole: .4,
													type: 'pie',
													direction: 'clockwise',
													sort: true,
													hoverinfo: 'none',
													textinfo: 'text',
													automargin: false
												}	
											]}
											config={{
												displayModeBar: false,
												responsive: true
											}}
											layout={{
												autosize: true, 
												template: "simple_white",
												font: { size: 20, family: "Titillium Web" },
												margin: {t:25,b:25,l:25,r:25},
												paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)",
												showlegend: false,
											}}
										/>
									</Fade>
								</Grid>
								
								<Loader isLoading={mortTableData.loading} style={{minHeight: '200'}}>
									<Fade triggerOnce duration={750} direction="down" style={{ height: "100%", width:"100%"}}>
										<MaterialTable
											columns={[
												{ title: '30d Risk Rate Cohort', field: 'cohort' },
												{ title: 'National Average', field: 'nationalrate' },
												{ title: 'April 2021 Star Release', field: 'hospitalrate' },
											]}
											data={mortTableData.data}
											options={{
												search: false, toolbar: false, showTitle: false, sorting: false,
												grouping: false, exportAllData: false, exportButton: false, 
												paging: false, showTitle: false, showTextRowsSelected: true,
												padding: "dense",
												//use pre determined sizes to figure out height of this table
												//subtract an extra 100 to account for header
												headerStyle: {
													zIndex: 0,
													//padding: 16,
													//whiteSpace: 'nowrap',
													//overflow: 'hidden',
													backgroundColor: secondaryColor,
													color: "white",
													fontFamily: "Titillium Web",
													textAlign: 'center',
													border: "solid black 1px",
													position: 'sticky', top: 0
												},
												cellStyle: {
													fontFamily: "Titillium Web",
													textAlign: 'center',
													paddingTop: '1px',
													paddingRight: '8px',
													paddingBottom: 0,
													paddingLeft: '8px',
												}
											}}
										/>
									</Fade>
								</Loader>
							</Grid>

							<Grid item xs={12} sm={7} md={7} lg={8} container alignItems="center" style={{position: "relative"}}>
								<Loader isLoading={mortOvrTimeChart.loading} style={{minHeight: '450px'}}>
									<Grid container>
										<Grid item xs={12} container justifyContent="flex-end" style={{marginTop: '25px'}}>
											<Fade triggerOnce duration={750} direction="up">
												<img src={MortHistFunnelLegend} style={{width: "200px"}}/>
											</Fade>
										</Grid>

										<Grid item xs={12}>
											<Fade triggerOnce duration={750} direction="up" style={{width: "100%"}}>
												<Plot
													style={{height:"450px", width: "100%", backgroundColor:"transparent"}}
													data={
														mortOvrTimeChart.data.map(item => {
														var markerColor = colorCohortHash[item.name]
															
														return {
															name: item.name,
															x: item.x,
															y: item.y,
															type: 'bar',
															hoverinfo: 'none',
															marker: {color: markerColor, line: {color: "#000000", width: 2}},
															textfont: {family: "Titillium Web"},
														}
													})}
													config={{
														displayModeBar: false,
														responsive: true
													}}
													layout={{
														autosize: true,
														annotations: {
															font: {
																size: 12, family: "Titillium Web" ,
															},
														},
														margin:{t:0, l:75, b:100, r:25},
														template: "simple_white",
														font: { size: 16, family: "Titillium Web" },
														showlegend: false,
														xaxis: { showline: true, linecolor:'black', categoryorder:'category ascending' },
														yaxis: { title: "Number of Cohort Encounters", showline: true, linecolor:'black' },
														barmode: "stack", paper_bgcolor: "rgba(0,0,0,0)", plot_bgcolor: "rgba(0,0,0,0)"
													}}
												/>
											</Fade>
										</Grid>
									</Grid>
								</Loader>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	)
}

export default withTheme(MortBar)