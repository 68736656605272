import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const primaryColor = "#0e3d46" //teal
export const secondaryColor = "#cb5e27" //orange

var theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#2e619f',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            //light: '#0066ff',
            main: '#8ac5f4',
            // dark: will be calculated from palette.secondary.main,
            //contrastText: '#ffcc00',
        },
    },
    typography: {
        fontFamily: "Titillium Web",
        h2: {
            color: secondaryColor,
        },
    },
});

theme = responsiveFontSizes(theme)

export default theme;