

import _ from 'lodash';
import { Typography, TextField, Divider } from '@material-ui/core';
import { Grid, Paper, Popover, Checkbox, Chip, Stack, Button, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';

import MaterialTable from 'material-table';
import React, { Component } from 'react';

import { adminApiFetch } from '../../config/msalConfig';
import store from '../../redux/store';

class UpdtProviders extends Component {
    constructor(props) {
        super(props);

        this.mounted = true;
        this.state = {
            rowData: _.cloneDeep(props.rowData),
            providerData: _.cloneDeep(props.providerData),
            selectedProviders: _.cloneDeep(props.rowData.providers) || [],
            searchTxt: null,
            searchProviders: [],
            checkedProviders: [],
            isOpen: false,
            anchorEl: null,
        }

        this.onChange = props.onChange
    }

    //open/close popover function
    _handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget, isOpen: true})
    };
    
    _handleClose = () => {
        this.setState({anchorEl: null, isOpen: false})
        this.onChange(this.state.selectedProviders)
    };

    //search functions
    _handleProviderSearchChange = (event) => {
        var text = event.target.value
        this.setState({searchTxt: text}, () => {
            if(text.length >=3){ //limit search to 3 min characters
                var selectedProviders = this.state.selectedProviders;

                //reset check providers to only contain provider ids from the selected providers
                //since the search providers can change with completely different data
                //theres no point in retaining their checked records
                var checkedProviders = this.state.checkedProviders.filter(x => selectedProviders.includes(x));

                this.setState({
                    searchProviders: this._searchProviders(text),
                    checkedProviders: checkedProviders
                })
            }
        })
    }

    _searchProviders = (searchTxt, excludeProviders = []) => {
        if(searchTxt == null || searchTxt.length < 3)
            return []

        searchTxt = searchTxt.toLowerCase();

        var searchableProviders = this.state.providerData
            .filter(x => 
                !this.state.selectedProviders //filter out already selected providers or any additional providers
                .concat(excludeProviders)   //we passed through to this method
                .includes(x.providerId) &&
                (
                    //search for both id and hosp names
                    x.providerId.toLowerCase().includes(searchTxt) ||
                    !_.isEmpty( //check if curr prov has a hospname like the search
                        x.hospitalNames.filter(hosp => hosp.toLowerCase().includes(searchTxt))
                    )
                )
            )

        return searchableProviders
    }

    //add/remove providers functions
    _addProviders = (ids) => {
        var selectedProviders = this.state.selectedProviders;
        
        selectedProviders = selectedProviders.concat(ids)
        selectedProviders = _.uniq(selectedProviders)

        this.setState({
            selectedProviders,
            searchProviders: this._searchProviders(this.state.searchTxt, selectedProviders)
        })
    }

    _removeProviders = (ids) => {
        var selectedProviders = this.state.selectedProviders.filter(x => !ids.includes(x));
        selectedProviders = _.uniq(selectedProviders)
        
        this.setState({
            selectedProviders,
            searchProviders: this._searchProviders(this.state.searchTxt, selectedProviders)
        })
    }

    _handleCheckedToggle = (id) => {
        var checkedProviders = this.state.checkedProviders;

        //check if prov id already exists in list
        var exists = !_.isEmpty(checkedProviders.filter(x => x == id))
        if(exists){ //we need to remove
            checkedProviders = checkedProviders.filter(x => x != id)
        }
        else{ //we need to add
            checkedProviders.push(id)
        }

        this.setState({checkedProviders})
    }   

    render(){
        return(
            <Grid container style={{height:"100%", cursor: "pointer"}}>
                {
                    _.isEmpty(this.state.selectedProviders) ?
                        <Grid container justifyContent="center" style={{height:"100%"}} onClick={this._handleClick}>
                            <Button variant="contained" color="primary" onClick={this._handleClick}>
                                Add New Provider
                            </Button>
                        </Grid>
                    :
                        <Grid container style={{height:"100%"}} onClick={this._handleClick}>
                            <Stack direction="row" spacing={1} style={{cursor: "pointer"}}>
                                {
                                    this.state.selectedProviders.map(prov => (
                                        <Chip label={prov} variant="outlined" style={{cursor: "pointer"}}/>
                                    ))
                                }
                            </Stack>
                        </Grid>
                }
                
                <Popover
                    open={this.state.isOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={this._handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style:{
                            padding: '8px',
                            width: '700px',
                            backgroundColor: "rgb(213 218 230)"
                        }
                    }}
                >
                    <Grid container>
                        {/* Search/Add Providers */}
                        <Grid item xs={5} container>
                            <Paper style={{height: "400px", width: "100%", padding: "8px"}}>
                                <Grid item xs={12} style={{height: "50px"}}>
                                    <TextField fullWidth variant="standard"
                                        label="Search By Name Or Id (3 char min)"
                                        value={this.state.searchTxt}
                                        onChange={this._handleProviderSearchChange}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{height: "calc(100% - 50px)", overflowY: 'scroll'}}>
                                    {
                                        _.isEmpty(this.state.searchProviders) ?
                                            <Grid container style={{height: "100%"}} alignItems="center" justifyContent="center">
                                                <Typography variant="h6" component="div">
                                                    Search For Providers
                                                </Typography>
                                            </Grid>
                                        :
                                            <List>
                                                {
                                                    // filter out selected providers from our add list
                                                    this.state.searchProviders
                                                        .filter(x => !this.state.selectedProviders.includes(x.providerId))
                                                        .map(prov => {
                                                        
                                                        var hospNames = 
                                                            this.state.searchProviders.filter(x => x.providerId == prov.providerId)
                                                            .map(x => x.hospitalNames).join('_')

                                                        return(
                                                            <ListItem disablePadding button
                                                                onClick={() => this._handleCheckedToggle(prov.providerId)}
                                                            >
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        checked={this.state.checkedProviders.indexOf(prov.providerId) !== -1}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText primary={prov.providerId} secondary={hospNames}/>
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </List>
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        
                        {/* Transfer Buttons */}
                        <Grid item xs={2} container justifyContent="center" alignContent="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={ () =>
                                    this._addProviders(
                                        this.state.searchProviders.map(x => x.providerId)
                                    )
                                }
                                disabled={_.isEmpty(this.state.searchProviders)}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={ () =>
                                    this._addProviders(
                                        this.state.searchProviders
                                        .filter(x => this.state.checkedProviders.includes(x.providerId))
                                        .map(x => x.providerId)
                                    )
                                }
                                disabled={
                                    _.isEmpty(this.state.searchProviders) || 
                                    _.isEmpty(
                                        this.state.checkedProviders
                                        .filter(x => this.state.searchProviders
                                            .map(y => y.providerId).includes(x)
                                        )
                                    )
                                }
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={ () =>
                                    this._removeProviders(
                                        this.state.selectedProviders
                                        .filter(x => this.state.checkedProviders.includes(x))
                                    )
                                }
                                disabled={
                                    _.isEmpty(this.state.selectedProviders) || 
                                    _.isEmpty(
                                        this.state.checkedProviders
                                        .filter(x => this.state.selectedProviders.map(y => y).includes(x))
                                    )
                                }
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={ () =>
                                    this._removeProviders(
                                        this.state.selectedProviders.map(x => x)
                                    )
                                }
                                disabled={_.isEmpty(this.state.selectedProviders)}
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </Grid>
                        
                        {/* Selected/Remove Providers */}
                        <Grid item xs={5} container>
                            <Paper style={{height: "400px", width: "100%", padding: "8px"}}>
                                <Grid item xs={12} container alignContent="center" justifyContent="center" style={{height: "50px"}}>
                                    <Typography variant="h6" component="div">
                                        Selected Providers {(this.state.selectedProviders.length)}
                                    </Typography>
                                </Grid>
                                <Divider/>

                                <Grid item xs={12} style={{height: "calc(100% - 50px)", overflowY: 'scroll'}}>
                                    {
                                        _.isEmpty(this.state.selectedProviders) ?
                                            <Grid container style={{height: "100%"}} alignItems="center" justifyContent="center">
                                                <Typography variant="h6" component="div">
                                                    No Selected Providers
                                                </Typography>
                                            </Grid>
                                        :
                                            <List>
                                                {
                                                    this.state.selectedProviders.map(prov => {
                                                        
                                                        var hospNames = 
                                                            this.state.providerData.filter(x => x.providerId == prov)
                                                            .map(x => x.hospitalNames).join('_')
                                                        return(
                                                            <ListItem disablePadding button
                                                                onClick={() => this._handleCheckedToggle(prov)}
                                                            >
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        checked={this.state.checkedProviders.indexOf(prov) !== -1}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText primary={prov} secondary={hospNames}/>
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </List>
                                    }
                                </Grid>
                        
                            </Paper>
                        </Grid>
                    </Grid>
                </Popover>
            </Grid>
        )
    }
}

class Users extends Component {
    constructor(props) {
        super(props);

        this.mounted = true;
        this.state = {
            isLoading: true,
            providerData: [],
            userData: []
        }
    }

    sendSnackMsg = (snackObj) => {
        store.dispatch({
            type: "ShowSnack",
            message: snackObj.msg,
            variant: snackObj.status,
        })
    }

    _validateSetState(props) {
        this.mounted && this.setState({ ...props })
    }
    
    async componentDidMount() {
        try{
            var res = await adminApiFetch('GetUsers')
            this._validateSetState({userData: res.userData, providerData: res.providerData, isLoading: false})
        }
        catch{
            this._validateSetState({isLoading: false})
        }
    };

    componentWillUnmount() {
    }

    render(){
        return (
            <Grid container style={{height: "100%", width: "100%"}}>
                <Grid item xs={12} style={{padding: "24px"}}>
                    <MaterialTable
                        isLoading={this.state.isLoading}
                        columns={[
                            { title: 'Email', field: 'email' },
                            { 
                                title: 'Providers', field: 'providers',
                                render: rowData => {
                                    return(
                                        <>
                                            {
                                                rowData.providers.map(id => {
                                                    var hospNames = 
                                                        this.state.providerData.filter(x => x.providerId == id)[0].hospitalNames

                                                    return(
                                                        <>
                                                            {
                                                                hospNames.map(name => (
                                                                    <Typography>
                                                                        {`${id}(${name})`}
                                                                    </Typography>
                                                                ))
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                },
                                editComponent: props => {
                                    return (
                                        <UpdtProviders
                                            onChange={props.onChange}
                                            rowData={props.rowData}
                                            providerData={this.state.providerData}
                                        />
                                    )
                                }
                            },
                            { title: 'UserType', field: 'userType',
                                lookup:{ admin: 'admin', client: 'client' },
                                initialEditValue: 'client' 
                            },
                        ]}
                        data={this.state.userData}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {
                                        
                                        var oData = _.cloneDeep(oldData)
                                        delete oData.tableData
                                        if(_.isEqual(oData, newData)){
                                            //nothing changed so do nothing

                                            this.sendSnackMsg({
                                                msg: 'No changes were made',
                                                status: "warning"
                                            })
                                            reject('No changes were made')
                                            return
                                        }

                                        newData.oldEmail = oData.email;

                                        var res = await adminApiFetch('UpdateUser', {userObj: newData})
                                        
                                        if(res != null){
                                            var userData = this.state.userData;
                                            var index = userData.map(x => x.email).indexOf(oldData.email);
                                            newData.tableData = userData[index].tableData

                                            userData[index] = newData
                                            this._validateSetState({userData})
                                            
                                            this.sendSnackMsg({
                                                msg: 'User Updated',
                                                status: "success"
                                            })
                                            resolve()
                                        }
                                        else{
                                            this.sendSnackMsg({
                                                msg: 'Error occured while updating user',
                                                status: "error"
                                            })
                                            reject('error')
                                        }
                                    }, 1000);
                                }),
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(async () => {      
                                        if(newData.email === '' || newData.email == null){
                                            this.sendSnackMsg({
                                                msg: 'Please enter email',
                                                status: "warning"
                                            })
                                            reject('error')
                                            return
                                        }
                                        if(!_.isEmpty(this.state.userData.filter(x => x.email == newData.email))){
                                            this.sendSnackMsg({
                                                msg: 'Email already exists',
                                                status: "warning"
                                            })
                                            reject('error')
                                            return
                                        }
                                        
                                        if(_.isEmpty(newData.providers) || newData.providers == null){
                                            this.sendSnackMsg({
                                                msg: 'Providers cannot be empty',
                                                status: "warning"
                                            })
                                            reject('error')
                                            return
                                        }                                        
                                        
                                        var res = await adminApiFetch('AddUser', {userObj: newData})
                                        if(res != null){
                                            var userData = this.state.userData;

                                            userData.push(newData)
                                            this._validateSetState({userData})

                                            this.sendSnackMsg({
                                                msg: 'User Saved',
                                                status: "success"
                                            })
                                            resolve()
                                        }
                                        else{
                                            this.sendSnackMsg({
                                                msg: 'Error occured while saving new user',
                                                status: "warning"
                                            })
                                            reject('error')
                                        }

                                    }, 1000);
                                })
                        }}
                        options={{
                            rowStyle:{
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                            },
                            showTitle: false,
                            exportButton: true,
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default Users;