import React, { useEffect } from 'react';
import _ from 'lodash';
import '../stylesheets/Login.css';

//imgs
import blueGrpahLogo from '../media/background-line-graph-notext.webp'
import trindaLogo from '../media/logo-trinda.png'
import careJourneyLogo from '../media/carejourney-logo.png'

//icons
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

//components
import { withTheme } from '@mui/styles';
import {Grid, Paper, Typography, Button, IconButton, TextField, Dialog, CircularProgress} from '@mui/material';

//msal
import { msalInstance, authenticationParameters } from '../config/msalConfig';
import { salesApiFetch } from '../containers/SalesContainer';
import {OpenCareJourneyBannerLink} from '../App'

//redux
import store from '../redux/store';
import { connect } from 'react-redux';
import watch from 'redux-watch'

// Google Global Site Tag
import ReactGA from 'react-ga';
ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function Login(props){
  const isAdminSite = window.location.host.split('-').map(x => x.split('.')).flatMap(x => x).includes("admin")
  const [user, setUser] = React.useState(null)
  const [loginIsOpen, setLoginIsOpen] = React.useState(props.loginIsOpen)

  const urlParams = new URLSearchParams(window.location.search)
  var email = urlParams.get("email")
  var provId = urlParams.get("provid")

  const [lastEmail, setLastEmail] = React.useState()
  const [loginEmail, setLoginEmail] = React.useState(email)
  const [adminProvId, setAdminProvId] = React.useState(provId)

  const [loading, setIsLoading] = React.useState(loginEmail ? true : false)
  const [requestLoading, setIsRequestLoading] = React.useState(false)

  useEffect(() => {
    //try to auto login if
    if(isAdminSite){ //we loaded from the admin domain
      handleAdminLogin()
    }
    else if(loginEmail){ //we loaded from the normal site with an email
      validateEmail()
    }

    return () => {
    }
  }, []);

  useEffect(() => {
    setLoginIsOpen(props.loginIsOpen)
  }, [props.loginIsOpen])

  function handleEmailChange(event){
    setLoginEmail(event.target.value)
  }

  function emailEnterKeyPress(e){
    if(e.keyCode == 13){
      validateEmail()
    }
  }

  async function validateEmail(azAccount = null){
    var email = _.cloneDeep(loginEmail)
    var isAuthenticated = false
    if(azAccount != null){
      isAuthenticated = true;
      email = azAccount.username
    }

    ReactGA.event({ // Google: Track request access click
      category: 'User',
      action: 'email_login_attempt',
      label: 'Email Login Attempt - ' + loginEmail
    });

    if(!email)
    {
      store.dispatch({
        type: "ShowSnack",
        message: "No Email Entered",
        variant: 'Warning',
      })
      return
    }

    if(lastEmail == email){
      //if they are the same then just close the login page,
      //they are already logged in with this email so it must have worked already
      //so no point in trying to reauthenticate

      setIsLoading(false)
      store.dispatch({ type: "CloseLogin" })
      return
    }

    try{
      //if we have an provid from url then pass it to the api so it can handle if user has admin access
      var extrabody = {
        email
      }

      if(adminProvId)
        extrabody.provid = adminProvId;

      var json = await salesApiFetch("GetTrindaUserData", 0, extrabody);
      
      if(json.status){ //api call passed
        var user = json.data

        //validate if user is admin and trying to access admin site
        if(isAdminSite && !user.isAdmin){
          store.dispatch({
            type: "ShowSnack",
            message: "You do not have an admin account",
            variant: 'Error',
          })
          
          setIsLoading(false)
          return
        }

        var providerItem = user.providerIdList.find(providerItem => providerItem.providerId === user.selectedProviderId)
        user.selectedHospitalName = providerItem.hospitalName
        
        setUser(user)
        setLastEmail(loginEmail)
        setIsLoading(false)

        if(email != loginEmail)
          setLoginEmail(email) //if were using an email from another entry point (admin site) then overwrite the login email

        store.dispatch({ type: "CloseLogin" })
        store.dispatch({ type: "SetUser", isAuthenticated, user })
      }
      else{
        throw new Error(json.errMsg)
      }
    }
    catch(err){ //api call failed
      var errMsg = err.message
      //if no provider id or user then display returned message
      var msg = "Error encountered while validating user. Please verify email address." //default user error msg
      if(errMsg.includes("No Provider") || errMsg.includes("No User"))
        msg = errMsg

      store.dispatch({
        type: "ShowSnack",
        message: msg,
        variant: 'Error',
      })

      setIsLoading(false)
    }
  }

  //admin msal login
  async function handleAdminLogin(){
    setIsLoading(true)
    try {
      var selAccount = await msalInstance.handleRedirectPromise()
      .then(resp => handleResponse(resp))
      .catch((error) => {
        console.log(error)
        return null
      });


      if(selAccount != null){
        msalInstance.setActiveAccount(selAccount);
      }
      else{
        throw new Error("Login Error")
      }

      //run login
      await validateEmail(selAccount)

    } catch (err) {
      // handle error
      console.log(err)
      store.dispatch({
        type: "ShowSnack",
        message: `Could Not Log In`,
        variant: 'Error',
      })

      setIsLoading(false)
    }
  }

  function handleResponse(resp) {
    var selectedAccount = null
    //check if account already active
    var accounts = msalInstance.getAllAccounts();

    if(_.isEmpty(accounts) && resp == null){
      //we need to login here, no accounts and no response
      return msalInstance.loginRedirect(authenticationParameters)
    }
    else{
      if(_.isEmpty(accounts)){
        //we got a response
        selectedAccount = resp.account
      }
      else{
        //we have accounts
        var activeAccount = msalInstance.getActiveAccount();
        if(activeAccount == null){
          activeAccount = accounts[0]
        }
        
        selectedAccount = activeAccount
      }
    }

    return selectedAccount;
}
  async function requestAccess(){
    ReactGA.event({ // Google: Track request access click
      category: 'User',
      action: 'access_request',
      label: 'Login Screen - User Requested Access'
    });

    if(!loginEmail) //email is empty
    {
      
      store.dispatch({
        type: "ShowSnack",
        message: `Please Enter An Email To Request Access`,
        variant: 'warning',
      })

      setIsRequestLoading(false)
      return
    }
    else{
      try{
        await salesApiFetch("SetLogRecord", 0, {email: loginEmail, action: "Access Request"});

        store.dispatch({
          type: "ShowSnack",
          message: "Your Access Request Has Been Submitted",
          variant: 'Success',
        })
      }
      catch(err){
        store.dispatch({
          type: "ShowSnack",
          message: err.message,
          variant: 'Error',
        })
      }
    }

    setIsRequestLoading(false)
  }

  return (
    <Dialog
      open={loginIsOpen}
      fullScreen
      PaperProps={{
        style:{
          background: `url("${blueGrpahLogo}") center / cover no-repeat`
        }
      }}
    >
      {
        loading && (
          <Grid container
            style={{
              height: '100%', width: '100%',
              position:"fixed", zIndex: 9999, backgroundColor:"rgba(0,0,0, .25)"
            }}
            alignItems="center" justifyContent="center"
          >
            <CircularProgress style={{height: "100px", width:"100px"}} />
          </Grid>
        )
      }

      <Grid container alignItems="center" justifyContent="center"
        style={{
          height: "100%", width: "100%", position:"relative",
        }}
      >
        <Grid item xs={12} sm={10} md={8} container className="loginContainer">
          <Paper className="loginPaper" elevation={10}>
            <Grid container alignItems="center" justifyContent="center" style={{height: "100%", position:"relative"}}>
              {user && (
                <IconButton aria-label="close" style={{position:"absolute", top: 0, right: 0}} 
                  onClick={() => 
                    store.dispatch({ type: "CloseLogin" })
                  }
                >
                  <CloseIcon />
                </IconButton>
              )}

              {/* logo and title */}
              <Grid item xs={12} container>
                <Grid item xs={12} md={3} container alignItems="center" justifyContent="center">
                  <img src={trindaLogo} className="loginLogoImg" alt="Trinda Health"/>
                </Grid>

                <Grid item xs={12} md={9} container alignItems="center">
                  <Typography variant="h4" className="loginTitle">
                    <b>Welcome to Trinda Health's CMS Star Predictor Model</b>
                  </Typography>
                </Grid>
              </Grid>

              {/* Care Journey Banner */}
              <Grid item xs={11} container justifyContent="center" alignItems="center" style={{height: 75}}>
                <Grid style={{height: "100%"}} item xs={12} container justifyContent="center" alignItems="center">
                  <Paper style={{height: "100%", backgroundColor: "transparent"}} elevation={0}>
                    <Grid item xs={12} style={{height: "100%"}} container justifyContent="center" alignItems="center">
                      <Typography style={{fontWeight:"bold", marginRight: "5px"}}>Data provided by</Typography>
                        <img style={{height: "40px", cursor:"pointer"}} src={careJourneyLogo} alt="Open Care Journey Link" onClick={OpenCareJourneyBannerLink}/>
                      <OpenInNewIcon style={{cursor:"pointer"}} onClick={OpenCareJourneyBannerLink}/>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              {/* intro and email */}
              <Grid item xs={11} container className="loginIntroAndEmailContainer">
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Utilizing publicly available data and data sourced from the CMS VRDC,
                    which is then processed by our proprietary algorithms,
                    we are able to highlight the clinical documentation improvement opportunities which
                    could lead to significant improvement in your <b>CMS Star Rating</b>.
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    The following charts give you a snapshot of those improvements.
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Contact Trinda Health for more insights on how our technology can improve your quality scores
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={8} xl={6} style={{margin: '25px 0px'}}>
                  <Typography variant="body1">
                    Please login using the email address that you received from the link
                  </Typography>

                  <TextField type="email" variant="standard" fullWidth autoFocus
                    margin="dense"
                    label="Email Address"
                    placeholder={loginEmail}
                    InputLabelProps={{
                      style:{
                        // fontSize: "18px",
                        fontFamily: "Titillium Web"
                      }
                    }}
                    inputProps={{
                      style:{
                        // fontSize: "18px",
                        fontFamily: "Titillium Web"
                      }
                    }}
                    InputProps={{
                      style:{
                        // fontSize: "18px",
                        fontFamily: "Titillium Web"
                      }
                    }}
                    onChange={handleEmailChange}
                    onKeyDown={emailEnterKeyPress}
                  />
                </Grid>
              </Grid>

              {/* buttons */}
              <Grid item xs={11} container alignContent="center" justifyContent="center">
                <Grid item xs={12} sm={6} container alignContent="center" justifyContent="flex-start">
                  <Button variant="contained"
                    className="loginOpenBtn"
                    onClick={() => {
                      setIsLoading(true)
                      validateEmail()
                    }}
                  >
                    <b>Open</b>
                  </Button>
                </Grid>
                
                <Grid item xs={12} sm={6} container alignContent="center" justifyContent="flex-end">
                  <Button variant="contained"
                    className="loginReqAccessBtn"
                    onClick={() => {
                      setIsRequestLoading(true)
                      requestAccess()
                    }}
                  >
                    {
                      requestLoading ?
                        <CircularProgress size={30} thickness={5} style={{color: "white"}} />
                      :
                        <b>Request Access</b>
                    }
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
      
        </Grid>
      </Grid>
    
      {/* <Fab 
        style={{backgroundColor: secondaryColor, color:'white', position: 'fixed', bottom: 8, right: 8}} 
        variant="extended" size="medium" aria-label="add"
        onClick={() => this.handleAdminLogin()}
      >
        <AdminPanelSettingsIcon sx={{ mr: 1 }} />
        Admin Portal
      </Fab> */}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  var userState = state.userReducer

  return {
    userState
  }
}
export default withTheme(connect(mapStateToProps)(Login));

