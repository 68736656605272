const cohortList = ["MORT_AMI", "MORT_HF", "MORT_PN", "MORT_COPD","MORT_CABG", "MORT_STR","READ_AMI", "READ_HF", "READ_PN", "READ_COPD","READ_CABG","HWR_MED", "HWR_SURGGYNE", "HWR_CARDRESP","HWR_CARDVASC","HWR_NEURO"]
const colorList = ["#4169e1","#ffa500","#808080", "#FFFF00","#00FF00", "#add8e6", "#4169e1","#ffa500","#808080", "#FFFF00","#00FF00", "#4169e1", "#006400", "#808080", "#00FF00", "#add8e6"]

function colorCohortHashFunc(){
  var hash = {}

  cohortList.forEach(cohort => {
    var index = cohortList.indexOf(cohort)
    var color = colorList[index];
    
    hash[cohort] = color
  });

  return hash
}

const colorCohortHash = colorCohortHashFunc()

export default colorCohortHash