

import Grid from '@material-ui/core/Grid';
import { withTheme } from '@mui/styles';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { adminApiFetch } from '../../config/msalConfig';

class Logs extends Component {
    constructor(props) {
        super(props);

        this.mounted = true;
        this.state = {
            isLoading: true,
            logData: []
        }
    }
    _validateSetState(props) {
        this.mounted && this.setState({ ...props })
    }
    
    async componentDidMount() {
        try{
            var logData = await adminApiFetch('GetLogs')
            this._validateSetState({logData, isLoading: false})
        }
        catch{
            this._validateSetState({isLoading: false})
        }
    };

    componentWillUnmount() {
    }

    render(){
        return (
            <Grid container style={{height: "100%", width: "100%"}}>
                <Grid item xs={12} style={{padding: "24px"}}>
                    <MaterialTable
                        isLoading={this.state.isLoading}
                        columns={[
                            { title: 'Email', field: 'email' },
                            { title: 'Request', field: 'action' },
                            { title: 'Date', field: 'date', type: 'date', filtering:true },
                        ]}
                        data={this.state.logData}
                        options={{
                            rowStyle:{
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                            },
                            showTitle: false,
                            exportButton: true,
                            filtering: true
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default withTheme(Logs);