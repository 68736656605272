import React from 'react';
import '../../stylesheets/PostROI.css'

import { salesApiFetch } from '../../containers/SalesContainer'
import store from '../../redux/store';

//imgs
import { Grid, Paper, Typography, Button, CircularProgress } from '@mui/material';

//transitions
import { AttentionSeeker } from 'react-awesome-reveal';

import { withTheme } from '@mui/styles';

// Google Global Site Tag
import ReactGA from 'react-ga';

ReactGA.initialize('UA-179778879-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const secondaryColor = "#cb5e27" //orange

function PostROI(props){
  const [requestLoading, setIsRequestLoading] = React.useState(false)

  async function requestInfo(){
    ReactGA.event({ // Google: Track request access click
      category: 'User',
      action: 'info_request',
      label: 'Main Screen - User Requested Info'
    });

    try{
      let json = await salesApiFetch("SetLogRecord", 0, {action: "Info Request"});
      if(json.status){
        store.dispatch({
          type: "ShowSnack",
          message: "Your Request Has Been Submitted",
          variant: 'Success',
        })
      }
    }
    catch(err){
      store.dispatch({
        type: "ShowSnack",
        message: err.message,
        variant: 'Error',
      })
    }

    setIsRequestLoading(false)
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className="slideContainer">
      <Grid item xs={10} container spacing={2} alignItems="center" justifyContent="center" style={{zIndex: 1}}>
        <Paper elevation={24} className="PostROIPaper">
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={10} container alignItems="center" justifyContent="center" className="PostROIContainer">
              <Typography align="center" variant="h3" style={{color: "black"}}>
                Let us show you the Star Rating results of the MDAR improvements you've previewed, along with additional forecasted financial opportunities.
                <br/>
                Click on the link below for your organizations CMS star prediction forecast.
              </Typography>

              <Grid item xs={12} sm={8} md={6} lg={4} container alignItems="center" justifyContent="center" style={{marginTop: "25px"}}> 
                <AttentionSeeker effect="pulse" style={{width: "100%"}}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Button variant="contained"
                      style={{
                        width: "100%", fontFamily: "Titillium Web",
                        backgroundColor: "white", color: secondaryColor
                      }}
                      onClick={() => {
                        setIsRequestLoading(true)
                        requestInfo()
                      }}
                    >
                      {
                        requestLoading ?
                          <CircularProgress size={30} thickness={5} style={{color: secondaryColor}}/>
                        :
                          <b>Request More Information</b>
                      }
                    </Button>
                  </Grid>
                </AttentionSeeker>
              </Grid>
            </Grid>

          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withTheme(PostROI);

