import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { msalInstance } from '../config/msalConfig';
import { withTheme, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import clsx from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';

import { Divider, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

//icons
import MenuIcon from '@material-ui/icons/Menu';
import NotesIcon from '@material-ui/icons/Notes';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GroupIcon from '@material-ui/icons/Group';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

const theme = createTheme();
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontFamily: 'Titillium Web'
    },
    menuIconTop: {
        paddingTop: 14,
        paddingLeft: 16,
        height: 36,
        color: 'white',
        backgroundColor: '#232f3e',
    },
    drawer: {
        display:"block !important",
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: '#1b2430 !important',
        color: 'white',
    },
    drawerPaper: {
        backgroundColor: 'transparent !important',
        overflow: 'hidden !important',
        height: '100%',
    },
    drawerClose: {
        width: "56px",
        transition: `${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })} !important`,
    },
    drawerOpen: {
        width: "250px",
        transition: `${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })} !important`,
    },
    content: {
      flex: 1,
      flexGrow: 1,
      display: 'flex',
      overflow: "hidden",
      height: "100%",
      transform: 'translateZ(0)'
    },
}));

function Layout(props){
    const classes = useStyles();
    var location = props.location;
    var isAuthenticated = props.isAuthenticated;

    const [drawerIsOpen, setDrawerIsOpen] = React.useState(false)

    function handleDrawerOpen() {
        setDrawerIsOpen(!drawerIsOpen)

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
        }, 250);

    }

    return (
        <Grid container style={{height: "100%", transition: "0.3s ease"}}>
            {
                isAuthenticated &&
                (
                    <Drawer
                        className={clsx(classes.drawer, { [classes.drawerOpen]: drawerIsOpen, [classes.drawerClose]: !drawerIsOpen, })}
                        classes={{ paper: clsx(classes.drawerPaper, { [classes.drawerOpen]: drawerIsOpen, [classes.drawerClose]: !drawerIsOpen, }), }}
                        variant="permanent"
                        open={drawerIsOpen}
                    >
                        <Grid item xs={12} container style={{height: '100%'}} justifyContent="center">
                            <Grid item xs={12} style={{height: "64px"}}>
                                <List>
                                    <ListItem button onClick={handleDrawerOpen}>
                                        <ListItemIcon>
                                            <MenuIcon style={{ color: '#b88e2e' }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="ADMIN TOOLS" style={{color: "white"}} />
                                    </ListItem>
                                </List>
                            </Grid>

                            <Divider style={{backgroundColor: "rgba(255,255,255, .8)", width:"100%"}} />

                            <Grid item xs={12} style={{height: `calc(100% - 130px)`}}>
                                <List>
                                    {
                                        [
                                            {route: '', incomingRoute: [''], icon: <AssessmentIcon/>, viewName: 'Sales View'},
                                            {route: 'CareJourney', incomingRoute: ['CareJourney'], icon: <AssessmentIcon/>, viewName: 'CareJourney'},
                                            {route: 'Logs', incomingRoute: ['Logs'], icon: <NotesIcon/>},
                                            {route: 'Providers', incomingRoute: ['Providers'], icon: <LocalHospitalIcon/>},
                                            {route: 'Users', incomingRoute: ['Users'], icon: <GroupIcon/>}
                                        ].map((item, index) => {

                                            var isSelected = 
                                                item.incomingRoute
                                                .map(x => "/" + x.replace(/\s/g, '').toLowerCase())
                                                .includes(location.pathname.toLowerCase());
                                            return (
                                                <ListItem button key={item.route}
                                                    component={NavLink}
                                                    to={"/" + item.route.replace(/\s/g, '')}
                                                    
                                                    // this is very subtle so may need to override material-ui
                                                    selected={isSelected}
                                                >
                                                    <ListItemIcon style={{ color: isSelected ? '#3ea5ff' : 'white' }}>{item.icon}</ListItemIcon>
                                                    <ListItemText primary={item.viewName || item.route} style={{ color: isSelected ? '#3ea5ff' : 'white' }}/>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </Grid>

                            <Grid item xs={12} style={{height: "64px"}}>
                                <Divider style={{backgroundColor: "rgba(255,255,255, .8)", width:"100%"}} />
                                <List>
                                    <ListItem button onClick={() => msalInstance.logoutRedirect()}>
                                        <ListItemIcon>
                                            <AccountCircleIcon style={{ color: 'white' }}/>
                                        </ListItemIcon>
                                        <ListItemText primary="Sign Out" style={{color: "white"}} />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Drawer>
                )
            }
            <main className={classes.content}>
                <Scrollbars autoHide hideTracksWhenNotNeeded ref={props.scrollBarRef}
                    style={{ width: "100%", height: "100%", overflowX: 'hidden' }}
                    renderThumbVertical={props.renderThumb}
                    renderView={
                        props => <div id="MktSiteContainer" {...props} style={{height:'100%',overflow:'overlay'}}/>
                    }
                >
                {/* <Grid container  style={{height: "100%", overflowX: 'hidden'}}> */}
                    {props.children}
                {/* </Grid> */}
                </Scrollbars>
            </main>
        </Grid>
    );
}

export default withRouter(
    withTheme(Layout)
);