import * as msal from "@azure/msal-browser";

//live api url
const apiUrl = `${window.location.origin + window.location.pathname}api/`

//local api debug
// const apiUrl = `http://localhost:7071/api/` //python api
// const apiUrl = `https://localhost:5001/api/` //.net api
// const apiUrl = 'https://star.trindahealth.com/api/' //live api for debugging


var clientId = "aea191ce-f360-4a23-ae81-3130891ed169";
// var clientSecret = "WC2g7Blh_-pQqbd7~7w-1W.VzrhH0bDa3M"

// Authentication Parameters
export const authenticationParameters = {
    scopes: [`${clientId}/.default`],
}

// Msal Configurations
const config = {
    auth: {
        clientId: clientId,
        redirectUri: window.location.origin,
        authority: 'https://login.microsoftonline.com/b5c613e7-0cc5-4201-9b3e-0654c97e9c83/'
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

export const msalInstance = new msal.PublicClientApplication(config);

export const adminApiFetch = async(path, body = null) => {
    try{
        return await msalFetch(path, body)
    }
    catch(err){

    }
}

export const msalFetch = async (path, body = null) => {
    var url = apiUrl + path;
    try {
        var fetchObj = {
            method: "GET",
            headers: {
                'Accept': 'application/json`',
                'Content-Type': 'application/json',
            }
        };

        if (body !== null) {
            fetchObj.method = "POST"
            fetchObj.body = JSON.stringify(body)
        }

        const res = await fetch(url, fetchObj);

        if(res.status !== 200){
            throw new Error(res.statusText)
        }
        
        var jsonRes = await res.json()
        return jsonRes;
    }
    catch (err) {
        return null
    }
};